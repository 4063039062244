import styled from 'styled-components';
import Breakpoint from '../../../../../../constants/Breakpoint.enum';

export const EmptyCartWrapper = styled.div`
    padding: 0 28px 32px 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: ${Breakpoint.Tablet}) {
        padding: 0px;
    }
`;
