import { Image } from '../types/common';

// type checkers

export function isObjectWithKey(data: unknown): data is { [key: string]: unknown } {
    return typeof data === 'object' && data !== null;
}

export function isString(data: unknown): data is string {
    return typeof data === 'string';
}

export function isNumber(data: unknown): data is number {
    return typeof data === 'number';
}

export function isBoolean(data: unknown): data is boolean {
    return typeof data === 'boolean';
}

export function isGenericArray<T>(data: unknown, typechecker: (data: unknown) => data is T): data is T[] {
    return Array.isArray(data) && data.every((item) => typechecker(item));
}

export function isImage(data: unknown): data is Image {
    return isObjectWithKey(data) && typeof data.id === 'number' && typeof data.path === 'string';
}

// getters

export function getGenericValue<T>(data: unknown, typechecker: (data: unknown) => data is T, defaultValue: T): T {
    if (typechecker(data)) {
        return data;
    }

    return defaultValue;
}

export function getGenericArray<T>(
    data: unknown,
    typechecker: (data: unknown) => data is T,
    defaultValue: T[] = [],
): T[] {
    if (Array.isArray(data)) {
        return data.reduce<T[]>((acc, value) => {
            if (typechecker(value)) {
                acc.push(value);
            }

            return acc;
        }, []);
    }

    return defaultValue;
}

export function getGenericObject<T>(
    data: unknown,
    typechecker: (data: unknown) => data is T,
    defaultValue: { [key: string]: T } = {},
): { [key: string]: T } {
    if (isObjectWithKey(data)) {
        return Object.entries(data).reduce<{ [key: string]: T }>((acc, [key, value]) => {
            if (typechecker(value)) {
                acc[key] = value;
            }

            return acc;
        }, {});
    }

    return defaultValue;
}

// primitives getters

export function getString(data: unknown, defaultValue = ''): string {
    return getGenericValue(data, isString, defaultValue);
}

export function getNumber(data: unknown, defaultValue = 0): number {
    return getGenericValue(data, isNumber, defaultValue);
}

export function getBoolean(data: unknown, defaultValue = false): boolean {
    return getGenericValue(data, isBoolean, defaultValue);
}

export function getStringArray(data: unknown, defaultValue: string[] = []): string[] {
    return getGenericArray(data, isString, defaultValue);
}
