import { CSSProperties } from 'react';
import styled from 'styled-components';
import Breakpoint from '../../constants/Breakpoint.enum';
import { Color } from '../../constants/Color.enum';

export const HEADER_DESKTOP_HEIGHT = '54px';
export const HEADER_MOBILE_HEIGHT = '40px';

interface HeaderProps {
    isVisible: boolean;
    transparent?: boolean;
    position?: CSSProperties['position'];
}

export const HeaderWrapperStyled = styled.div<HeaderProps>`
    background-color: ${({ transparent }) => (transparent ? 'transparent' : `${Color.Surface}`)};
    color: ${({ transparent }) => (transparent ? `${Color.Surface}` : 'initial')};
    justify-content: space-between;
    align-items: center;
    height: ${HEADER_DESKTOP_HEIGHT};
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    position: ${({ position = 'fixed' }) => position};
    z-index: 10;
    top: 0;
    left: 0;
    transform: ${(props) => (props.isVisible ? 'translateY(0%)' : 'translateY(-100%)')};
    transition: transform 0.5s, background 0.3s;

    @media (max-width: ${Breakpoint.Tablet}) {
        height: ${HEADER_MOBILE_HEIGHT};
        z-index: 100;
    }
`;

export const HeaderIconStyled = styled.div`
    padding: 4px 12px;
    width: ${HEADER_DESKTOP_HEIGHT};
    height: ${HEADER_DESKTOP_HEIGHT};
    cursor: pointer;
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;

    @media (max-width: ${Breakpoint.Tablet}) {
        height: ${HEADER_MOBILE_HEIGHT};
        width: ${HEADER_MOBILE_HEIGHT};
    }

    &:hover {
        opacity: 0.8;
    }
`;

export const HeaderLogoStyled = styled(HeaderIconStyled)`
    width: auto;
`;

export const ToggleVisibilityWrapperStyled = styled.div<Pick<HeaderProps, 'isVisible'>>`
    position: absolute;
    top: ${HEADER_DESKTOP_HEIGHT};
    left: 0;
    z-index: 10;
    width: ${HEADER_DESKTOP_HEIGHT};
    height: ${HEADER_DESKTOP_HEIGHT};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: ${({ isVisible }) => (isVisible ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: transform 0.3s;

    @media (max-width: ${Breakpoint.Tablet}) {
        top: 56px;
        height: 36px;
        width: 36px;
        left: 8px;
    }

    &:hover {
        opacity: 0.8;
    }
`;
