import styled from 'styled-components';
import { Color } from '../../../constants/Color.enum';

export const LoadingOverlayWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    opacity: 0.8;
    background-color: ${Color.Surface};
    pointer-events: 'none';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: center;
`;
