import { getPublicEnv } from '../env/env';
import { FetchService } from '../fetch/fetch';
import { getGenericArray, isNumber, isObjectWithKey, isString } from '../validateData';

export interface InstallationPartnerData {
    description?: null | string;
    id: number;
    image: { path: string } | null;
    name: string;
    zipCode: string;
}

export function isInstallationPartnerData(data: any): data is InstallationPartnerData {
    return (
        isObjectWithKey(data) &&
        isNumber(data.id) &&
        (data.image === null || (isObjectWithKey(data.image) && isString(data.image.path))) &&
        (data.description === null || data.description === undefined || isString(data.description)) &&
        isString(data.name) &&
        isString(data.zipCode)
    );
}

export class InstallationPartners {
    private fetch: FetchService;

    constructor(fetch: FetchService) {
        this.fetch = fetch;
    }

    async getInstallationPartners(): Promise<InstallationPartnerData[]> {
        const mediaDomainUrl = await getPublicEnv('MEDIA_DOMAIN_URL');
        const response = await this.fetch.get('/api/v2/shop/installation-partners');

        if (Array.isArray(response)) {
            return getGenericArray(response, isInstallationPartnerData).map((item) => ({
                ...item,
                image:
                    item.image === null
                        ? null
                        : {
                              path: mediaDomainUrl + item.image.path,
                          },
            }));
        }

        return [];
    }
}
