import { configureScope } from '@sentry/nextjs';
import { getPublicEnv } from './env';

export async function addEnvironmentNameToSentry() {
    const environmentName = await getPublicEnv('NEXT_PUBLIC_ENVIRONMENT_NAME');

    configureScope((scope) =>
        scope.addEventProcessor((event) => ({
            ...event,
            environment: (event.environment || '').includes(environmentName)
                ? event.environment
                : `${event.environment} - ${environmentName}`,
        })),
    );
}
