import React, { FC } from 'react';

export const ChevronUpIcon: FC = () => (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4995 5.79981L5.99951 1.2998L1.49951 5.7998"
            stroke="currentColor"
            strokeWidth="1.9"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
