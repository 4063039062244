import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { useEnv } from '../../hooks/useEnv';
import { RootState } from '../../store';
import { useStore } from 'react-redux';
import { getServices } from '../../store/dependency/selectors';

interface HistoryProviderContextValues {
    redirectAfterLogIn: () => void;
    saveConfiguratorPath: (asPath: string) => void;
}

const historyContext = createContext<HistoryProviderContextValues>({
    redirectAfterLogIn: () => {},
    saveConfiguratorPath: () => {},
});

export const useHistory = () => useContext(historyContext);

export const useSaveConfiguratorPath = () => {
    const { asPath } = useRouter();
    const { saveConfiguratorPath } = useHistory();
    const firstRun = useRef(true);

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            saveConfiguratorPath(asPath);
        }
    });
};

export const useRedirectionWhenUserIsLoggedIn = () => {
    const { getState } = useStore<RootState>();
    const { redirectAfterLogIn } = useHistory();

    useEffect(() => {
        if (typeof document !== 'undefined') {
            const { auth } = getServices(getState());

            auth.onAuthStateChanged((user) => {
                if (user) {
                    redirectAfterLogIn();
                }
            });
        }
    }, [getState, redirectAfterLogIn]);
};
interface HistoryProviderProps {
    children: React.ReactNode;
}

export const HistoryProvider = ({ children }: HistoryProviderProps) => {
    const { push } = useRouter();
    const defaultLoginRedirect = useEnv('DEFAULT_LOGIN_REDIRECT');

    const saveConfiguratorPath = useCallback((asPath: string) => {
        localStorage.setItem('lastVisitedConfigurator', asPath);
    }, []);

    const redirectAfterLogIn = useCallback(() => {
        const lastVisitedConfiguratorValue = localStorage.getItem('lastVisitedConfigurator');

        if (lastVisitedConfiguratorValue) {
            push(lastVisitedConfiguratorValue);
        } else if (defaultLoginRedirect) {
            push(defaultLoginRedirect);
        }
    }, [defaultLoginRedirect, push]);

    return (
        <historyContext.Provider value={{ saveConfiguratorPath, redirectAfterLogIn }}>
            {children}
        </historyContext.Provider>
    );
};
