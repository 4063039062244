import styled from 'styled-components';
import { Color } from '../../constants/Color.enum';

interface AccordionProps {
    isVisible?: boolean;
    withBorder?: boolean;
    height?: number;
}

export const TopStyled = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.047rem;
    color: ${Color.Primary};
`;

export const AccordionWrapperStyled = styled.div<AccordionProps>`
    user-select: none;
    background-color: ${Color.Surface};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border: 1px solid;
    border-color: ${(props) => (props.withBorder ? Color.Background : 'transparent')};
    padding: ${(props) => (props.withBorder ? '18px 20px' : 0)};

    &:hover ${TopStyled} {
        color: ${Color.Secondary};
    }
`;

export const ToggleVisibilityWrapperStyled = styled.div<AccordionProps>`
    cursor: pointer;
    transform: ${(props) => (props.isVisible ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s;
    z-index: 10;
`;

export const ExpansionPanelStyled = styled.div<AccordionProps>`
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    pointer-events: ${(props) => (props.isVisible ? 'all' : 'none')};
    max-height: ${(props) => {
        if (props.height) {
            return props.isVisible ? `${props.height}px` : 0;
        }
    }};
    overflow: ${(props) => (props.isVisible ? 'initial' : 'hidden')};
    position: ${(props) => (props.isVisible ? 'static' : 'relative')};
    margin-bottom: ${(props) => (props.isVisible ? '24px' : 0)};
`;
