import type { FirebasePerformance } from 'firebase/performance';
import { getDefaultFirebaseApp } from '../firebase/firebaseApp';

export class FirebasePerformanceService {
    private app: FirebasePerformance | null = null;

    constructor() {
        this.getApp();
    }

    async getApp(): Promise<FirebasePerformance> {
        if (this.app) {
            return this.app;
        }

        const app = await getDefaultFirebaseApp();
        const { getPerformance } = await import('firebase/performance');
        const appWithPerf = getPerformance(app);

        this.app = appWithPerf;

        return appWithPerf;
    }

    async trace(name: string) {
        const app = await this.getApp();
        const { trace } = await import('firebase/performance');
        const instance = trace(app, name);

        instance.start();

        return () => instance.stop();
    }
}
