import { ChannelService } from '../channel/channel';

export interface CurrencySettings {
    currencies: string[];
    defaultCurrency: string;
}

export class CurrencyService {
    private channel: ChannelService;

    constructor(channel: ChannelService) {
        this.channel = channel;
    }

    setCurrency(currencyCode: string): void {
        localStorage.setItem('currency', currencyCode);
    }

    getCurrentCurrency(): string | null {
        const saved = localStorage.getItem('currency');

        return saved;
    }

    async getCurrencySettings(): Promise<CurrencySettings> {
        return this.channel.getCurrencySettings();
    }
}
