import { RootState } from '../../../store';
import { Product } from '../../../types/Product';
import { availableProductCode, ProductCode } from '../../products/domain/ProductCodes';

export type AllProducts = { [type in Exclude<ProductCode, 'unknown'>]: Product };

const isAllProducts = (data: Partial<AllProducts>): data is AllProducts => {
    const {
        wardrobe,
        double_closet,
        single_closet,
        shelve,
        double_drawer,
        quad_drawer,
        door,
        door_handle_chrome,
        door_handle_gold,
        door_handle_wood,
        glass_shelve,
        hanger,
        hanger_front,
        door_glass,
        led,
        wardrobe_free_standing,
        wrapper,
        l_shaped_wardrobe_left,
        l_shaped_wardrobe_right,
        u_shaped_wardrobe,
        wardrobe_wall,
        spacing,
    } = data;

    return (
        wardrobe !== undefined &&
        double_closet !== undefined &&
        single_closet !== undefined &&
        shelve !== undefined &&
        double_drawer !== undefined &&
        quad_drawer !== undefined &&
        door !== undefined &&
        door_handle_chrome !== undefined &&
        door_handle_gold !== undefined &&
        door_handle_wood !== undefined &&
        glass_shelve !== undefined &&
        hanger !== undefined &&
        hanger_front !== undefined &&
        door_glass !== undefined &&
        wardrobe_free_standing !== undefined &&
        wrapper !== undefined &&
        led !== undefined &&
        l_shaped_wardrobe_left !== undefined &&
        l_shaped_wardrobe_right !== undefined &&
        u_shaped_wardrobe !== undefined &&
        wardrobe_wall !== undefined &&
        spacing !== undefined
    );
};

export const formatProducts = (products: Product[]): AllProducts | null => {
    const partialProduct = availableProductCode.reduce<Partial<AllProducts>>((acc, code) => {
        if (code === 'unknown') {
            return acc;
        }
        const product = products.find((product) => product.code === code);

        if (product) {
            acc[code] = product;
        }

        return acc;
    }, {});

    if (isAllProducts(partialProduct)) {
        return partialProduct;
    }

    return null;
};

export const getAllProducts = (state: RootState): AllProducts | null => state.products.formattedProducts;
