import {
    AnyAction,
    AsyncThunk,
    AsyncThunkOptions,
    AsyncThunkPayloadCreator,
    createAsyncThunk,
    Dispatch,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FallbackIfUnknown } from '@reduxjs/toolkit/dist/tsHelpers';
import { showToastr } from '../toastr/actions';
import { CancelError } from '../utils';
import { addBreadcrumb, captureException } from '@sentry/nextjs';
import { DEFAULT_ERROR } from '../../constants/errors/constants';

type AsyncThunkConfig = {
    state?: unknown;
    dispatch?: Dispatch;
    extra?: unknown;
    rejectValue?: unknown;
    serializedErrorType?: unknown;
    pendingMeta?: unknown;
    fulfilledMeta?: unknown;
    rejectedMeta?: unknown;
};

type GetState<ThunkApiConfig> = ThunkApiConfig extends {
    state: infer State;
}
    ? State
    : unknown;
type GetExtra<ThunkApiConfig> = ThunkApiConfig extends {
    extra: infer Extra;
}
    ? Extra
    : unknown;
type GetDispatch<ThunkApiConfig> = ThunkApiConfig extends {
    dispatch: infer Dispatch;
}
    ? FallbackIfUnknown<Dispatch, ThunkDispatch<GetState<ThunkApiConfig>, GetExtra<ThunkApiConfig>, AnyAction>>
    : ThunkDispatch<GetState<ThunkApiConfig>, GetExtra<ThunkApiConfig>, AnyAction>;

type GetRejectValue<ThunkApiConfig> = ThunkApiConfig extends {
    rejectValue: infer RejectValue;
}
    ? RejectValue
    : unknown;

type GetFulfilledMeta<ThunkApiConfig> = ThunkApiConfig extends {
    fulfilledMeta: infer FulfilledMeta;
}
    ? FulfilledMeta
    : unknown;
type GetRejectedMeta<ThunkApiConfig> = ThunkApiConfig extends {
    rejectedMeta: infer RejectedMeta;
}
    ? RejectedMeta
    : unknown;

type GetThunkAPI<ThunkApiConfig> = BaseThunkAPI<
    GetState<ThunkApiConfig>,
    GetExtra<ThunkApiConfig>,
    GetDispatch<ThunkApiConfig>,
    GetRejectValue<ThunkApiConfig>,
    GetRejectedMeta<ThunkApiConfig>,
    GetFulfilledMeta<ThunkApiConfig>
>;

export function createAsyncThunkWithShowError<Returned, ThunkArg = void, ThunkApiConfig extends AsyncThunkConfig = {}>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
    options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
): AsyncThunk<Returned, ThunkArg, ThunkApiConfig> {
    const wrapper: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig> = (
        arg: ThunkArg,
        thunkAPI: GetThunkAPI<ThunkApiConfig>,
    ) => {
        const result = payloadCreator(arg, thunkAPI);

        if (result instanceof Promise) {
            result.catch((error) => {
                if (error instanceof CancelError) {
                    return;
                }
                // eslint-disable-next-line no-console
                console.error(typePrefix, error);
                thunkAPI.dispatch(
                    showToastr({
                        description: DEFAULT_ERROR,
                    }),
                );
                try {
                    addBreadcrumb({
                        category: 'ACTION_NAME',
                        message: typePrefix,
                        level: 'info',
                    });
                    captureException(error);
                    // eslint-disable-next-line no-empty
                } catch (error) {}
            });
        }

        return result;
    };

    return createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(typePrefix, wrapper, options);
}
