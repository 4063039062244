import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Summary } from '../SidePanel/components/Summary/Summary';

interface CartContext {
    openCart: () => void;
    closeCart: () => void;
    isCartOpen: boolean;
    registerOnCloseHandler: (handler: () => void) => () => void;
}

export const cartContext = createContext<CartContext>({
    closeCart: () => {},
    openCart: () => {},
    isCartOpen: false,
    registerOnCloseHandler: () => () => {},
});

export const CartSidePanelProvider = ({ children }: { children: React.ReactNode }) => {
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [ready, setReady] = useState(false);
    const onCloseHandlers = useRef<(() => void)[]>([]);

    const openCart = useCallback(() => {
        setReady(true);
        setTimeout(() => {
            setIsCartOpen(true);
        }, 1);
    }, []);

    const closeCart = useCallback(() => {
        setIsCartOpen(false);
    }, []);

    useEffect(() => {
        if (isCartOpen) {
            document.body.style.overflow = 'hidden';

            return () => {
                document.body.style.overflow = 'auto';
            };
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isCartOpen]);

    const registerOnCloseHandler = useCallback((handler: () => void) => {
        onCloseHandlers.current.push(handler);

        return () => {
            onCloseHandlers.current = onCloseHandlers.current.filter((h) => h !== handler);
        };
    }, []);

    useEffect(() => {
        if (isCartOpen) {
            return;
        }
        onCloseHandlers.current.forEach((handler) => handler());
    }, [isCartOpen]);

    return (
        <cartContext.Provider
            value={useMemo(
                () => ({
                    openCart,
                    closeCart,
                    isCartOpen,
                    registerOnCloseHandler,
                }),
                [closeCart, isCartOpen, openCart, registerOnCloseHandler],
            )}
        >
            {ready && (
                <div
                    style={{
                        position: 'fixed',
                        zIndex: 1000,
                    }}
                >
                    <Summary
                        isOpen={isCartOpen}
                        onClose={() => {
                            closeCart();
                        }}
                    />
                </div>
            )}
            {children}
        </cartContext.Provider>
    );
};
