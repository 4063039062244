import styled from 'styled-components';

export const SummaryDetailsGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 6px;
    padding: 20px 0 35px 0;

    div:nth-child(even) {
        text-align: right;
    }

    div:nth-child(odd) {
        text-align: left;
    }
`;
