import { EventNames, EventPayload } from './EventPayload';

declare global {
    interface Window {
        hj?: (type: 'event', eventName: string) => void;
        dataLayerAnalytics?:
            | {
                  push: (param: any) => void;
              }
            | any[];
    }
}

const getPush = () => (arg: ['set' | 'event', any, any] | any) => {
    window.dataLayerAnalytics = window.dataLayerAnalytics || [];
    window.dataLayerAnalytics.push(arg);
};

export function pushEvent<T extends EventNames>(name: T, payload: EventPayload[T]) {
    const push = getPush();
    const hj = window.hj;

    if (hj) {
        hj('event', name);
    }

    push({ event: name, ...payload });
}

export function setPage(title: string, href: string) {
    const push = getPush();

    push(['set', 'page_location', href]);
    push(['set', 'page_title', title]);
}
