import React, { FC } from 'react';

export const ClearIcon: FC = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4586 1.62321C11.7912 1.29064 11.7912 0.751443 11.4586 0.418875C11.1261 0.0863062 10.5869 0.0863062 10.2543 0.418875L5.83893 4.83424L1.6244 0.619705C1.29183 0.287137 0.752627 0.287137 0.420059 0.619706C0.0874905 0.952274 0.0874905 1.49147 0.420059 1.82404L4.6346 6.03858L0.418879 10.2543C0.0863104 10.5869 0.0863104 11.1261 0.418879 11.4586C0.751448 11.7912 1.29065 11.7912 1.62322 11.4586L5.83893 7.24292L10.2555 11.6595C10.588 11.992 11.1272 11.992 11.4598 11.6595C11.7924 11.3269 11.7924 10.7877 11.4598 10.4551L7.04327 6.03858L11.4586 1.62321Z"
            fill="currentColor"
        />
    </svg>
);
