import { useState, useCallback, useMemo, useEffect } from 'react';
import { ColumnDetails, WardrobeWall, GroupResult } from '../services/nodes/wardrobe/visitor/GroupedVisitor';
import { RootState } from '../store';
import { useLockEffect } from './useLockEffect';
import {
    prepareFormifyWardrobeState,
    FormifyWardrobeNode,
    Measure,
    ProjectState,
} from '@formify/projects-state-manager';
import { useSelector } from 'react-redux';
import { useMeasureUnit } from '../components/MeasureUnit/MeasureUnitProvider';
import { createWardrobeState } from '../services/project/projectStateData';

interface GroupResultWithPrice extends GroupResult {
    price: number;
}

export interface GroupResultWithPriceWall {
    columns: GroupResultWithPrice[];
    wall: WardrobeWall;
    price: number;
    width: Measure;
    height: Measure;
    depth: Measure;
}

const indexToWardrobeWall: { [key: number]: WardrobeWall } = {
    0: 'A',
    1: 'B',
    2: 'C',
};

export const useProjectDetailsById = (projectId: string) => {
    const { currentMeasureUnit } = useMeasureUnit();

    const [projectStateNew, setProjectStateNew] = useState<Awaited<
        ReturnType<typeof prepareFormifyWardrobeState>
    > | null>(null);

    useEffect(() => {
        setProjectStateNew(null);
        createWardrobeState(true).then(async (state) => {
            await state.doAction('loadProject', { id: projectId });

            setProjectStateNew(state);
        });
    }, [projectId]);

    const [project, setProject] = useState<FormifyWardrobeNode<
        | 'wardrobe'
        | 'wardrobe_free_standing'
        | 'l_shaped_wardrobe_left'
        | 'l_shaped_wardrobe_right'
        | 'u_shaped_wardrobe'
    > | null>(null);

    const [grouped, setGrouped] = useState<GroupResultWithPriceWall[]>([]);
    const priceService = useSelector((state: RootState) => state.dependency.services?.price);

    const fetchProject = useCallback(async () => {
        const project = projectStateNew?.getProject();
        const additionalState = projectStateNew?.getState();

        if (project && additionalState && priceService) {
            if (
                project.checkTypeByCode('wardrobe') ||
                project.checkTypeByCode('wardrobe_free_standing') ||
                project.checkTypeByCode('l_shaped_wardrobe_left') ||
                project.checkTypeByCode('l_shaped_wardrobe_right') ||
                project.checkTypeByCode('u_shaped_wardrobe')
            ) {
                setProject(project);
            }
            const walls = project.findChildrenByProductCode(['wardrobe_wall']);
            const columns = project.findChildrenByProductCode(['double_closet', 'single_closet']);

            const wallIndexToColumnDetails: {
                column: FormifyWardrobeNode<'double_closet' | 'single_closet'>;
                details: ColumnDetails;
                quantity: number;
            }[][] = [];

            columns.forEach((column, index) => {
                const wallIndex = walls.indexOf(column.getParent() ?? (null as any));
                const params = column.getParams();

                const handleNodes = column.findChildrenByProductCode([
                    'door_handle_chrome',
                    'door_handle_gold',
                    'door_handle_wood',
                ]);

                const details: ColumnDetails = {
                    shelf: column.findChildrenByProductCode(['shelve']).length,
                    shelf_glass: column.findChildrenByProductCode(['glass_shelve']).length,
                    layout: additionalState.columnsSetting[index]?.layoutId ?? null,
                    depth: params.DEPTH,
                    height: params.HEIGHT,
                    width: params.WIDTH,
                    material: params.MATERIAL,
                    handle: handleNodes[0] ? handleNodes[0].getProduct().code ?? null : null,
                    doors: column.findChildrenByProductCode(['door', 'door_glass']).length > 0,
                    wall: indexToWardrobeWall[wallIndex] || 'A',
                };

                const group = (wallIndexToColumnDetails[wallIndex] ?? []).find(
                    (accData) => JSON.stringify(accData.details) === JSON.stringify(details),
                );

                if (group) {
                    group.quantity += 1;
                } else {
                    wallIndexToColumnDetails[wallIndex] = [
                        ...(wallIndexToColumnDetails[wallIndex] ?? []),
                        {
                            column,
                            details,
                            quantity: 1,
                        },
                    ];
                }
            });

            const groupedWall = wallIndexToColumnDetails.map((columns, index) => ({
                wall: indexToWardrobeWall[index] || 'A',
                columns,
            }));

            if (!projectStateNew) {
                return [] as GroupResultWithPriceWall[];
            }

            return await Promise.all(
                groupedWall.map(async (wallGroup): Promise<GroupResultWithPriceWall> => {
                    const columns = await Promise.all(
                        wallGroup.columns.map(async (column): Promise<GroupResultWithPrice> => {
                            const data = ProjectState.prepareProjectRequestData(column.column, currentMeasureUnit);
                            const columnWithPrice = await priceService.fetchPrice(data, 'EUR', currentMeasureUnit);
                            const price = columnWithPrice.unitPriceWithChildItems;

                            return { ...column, price: price || 0 };
                        }),
                    );

                    return {
                        columns,
                        wall: wallGroup.wall,
                        price: columns.reduce((sum, column) => sum + column.price * column.quantity, 0),
                        width: Measure.sum(
                            columns.map((column) => column.details.width.multiplyByNumber(column.quantity)),
                        ),
                        depth: Measure.max(columns.map((column) => column.details.depth)),
                        height: Measure.max(columns.map((column) => column.details.height)),
                    };
                }),
            );
        }

        return [] as GroupResultWithPriceWall[];
    }, [priceService, projectStateNew]);

    useLockEffect(fetchProject, setGrouped);

    return { grouped, project };
};

export const getWardrobeSize = (
    wardrobe: FormifyWardrobeNode<
        | 'wardrobe'
        | 'wardrobe_free_standing'
        | 'l_shaped_wardrobe_left'
        | 'l_shaped_wardrobe_right'
        | 'u_shaped_wardrobe'
    >,
): {
    width: Measure;
    height: Measure;
    depth: Measure;
} => {
    if (wardrobe.checkTypeByCode('wardrobe') || wardrobe.checkTypeByCode('wardrobe_free_standing')) {
        return {
            width: wardrobe.getParams().WIDTH,
            height: wardrobe.getParams().HEIGHT,
            depth: wardrobe.getParams().DEPTH,
        };
    } else if (wardrobe.checkTypeByCode('l_shaped_wardrobe_left')) {
        const [wallA, wallB] = wardrobe.findChildrenByProductCode(['wardrobe_wall']);

        const widthA = Measure.sum(
            wallA
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().WIDTH) || [new Measure(0, 'MM')],
        );

        const widthB = Measure.sum(
            wallB
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().WIDTH) || [new Measure(0, 'MM')],
        );

        const depthA = Measure.max(
            wallA
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().DEPTH) || [new Measure(0, 'MM')],
        );

        return {
            width: widthB.add(depthA),
            height: wardrobe.getParams().HEIGHT,
            depth: widthA,
        };
    } else if (wardrobe.checkTypeByCode('l_shaped_wardrobe_right')) {
        const [wallB, wallA] = wardrobe.findChildrenByProductCode(['wardrobe_wall']);

        const widthA = Measure.sum(
            wallA
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().WIDTH) || [new Measure(0, 'MM')],
        );

        const widthB = Measure.sum(
            wallB
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().WIDTH) || [new Measure(0, 'MM')],
        );

        const depthA = Measure.max(
            wallA
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().DEPTH) || [new Measure(0, 'MM')],
        );

        return {
            width: widthB.add(depthA),
            height: wardrobe.getParams().HEIGHT,
            depth: widthA,
        };
    } else if (wardrobe.checkTypeByCode('u_shaped_wardrobe')) {
        const [wallA, wallB, wallC] = wardrobe.findChildrenByProductCode(['wardrobe_wall']);

        const widthA = Measure.sum(
            wallA
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().WIDTH) || [new Measure(0, 'MM')],
        );

        const widthB = Measure.sum(
            wallB
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().WIDTH) || [new Measure(0, 'MM')],
        );

        const widthC = Measure.sum(
            wallC
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().WIDTH) || [new Measure(0, 'MM')],
        );

        const depthA = Measure.max(
            wallA
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().DEPTH) || [new Measure(0, 'MM')],
        );

        const depthC = Measure.max(
            wallC
                ?.findChildrenByProductCode(['double_closet', 'single_closet'])
                .map((column) => column.getParams().DEPTH) || [new Measure(0, 'MM')],
        );

        return {
            width: Measure.sum([widthB, depthA, depthC]),
            height: wardrobe.getParams().HEIGHT,
            depth: Measure.max([widthC, widthA]),
        };
    }

    return {
        width: new Measure(0, 'MM'),
        height: new Measure(0, 'MM'),
        depth: new Measure(0, 'MM'),
    };
};
