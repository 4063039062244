export enum Color {
    Alpha_30 = 'rgba(0, 0, 0, 0.3)',
    Background = '#f5f3f0',
    Black = '#000',
    Error = '#cb444b',
    Primary = '#333',
    Secondary = '#999999',
    Success = '#00BA88',
    Input_Success = '#0D8F6C',
    Surface = '#fff',
    Variant_1 = '#d9d9d9',
}
