import React, { FC } from 'react';

export const ChevronRightSmallIcon: FC = () => (
    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.62637 6.87325C0.42026 6.66714 0.42026 6.33297 0.62637 6.12686L2.75318 4.00005L0.62637 1.87325C0.42026 1.66714 0.42026 1.33297 0.62637 1.12686C0.83248 0.920749 1.16665 0.920749 1.37276 1.12686L3.87276 3.62686C3.97174 3.72584 4.02734 3.86008 4.02734 4.00005C4.02734 4.14003 3.97174 4.27427 3.87276 4.37325L1.37276 6.87325C1.16665 7.07936 0.83248 7.07936 0.62637 6.87325Z"
            fill="currentColor"
        />
    </svg>
);
