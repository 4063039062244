export class CancelError extends Error {}

export function promiseDebounce<P extends Array<any>, T>(func: (...args: P) => Promise<T>, wait = 100): typeof func {
    let cancel: () => void = () => {};

    return (...args: P) => {
        cancel();

        return new Promise<T>((resolve, reject) => {
            const timer = setTimeout(() => {
                resolve(func(...args));
            }, wait);

            cancel = () => {
                clearTimeout(timer);
                reject(new CancelError('Debounce cancel'));
            };
        });
    };
}
