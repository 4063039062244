import type { NextOrObserver, User } from 'firebase/auth';
import { NextPageContext } from 'next';
import { FirebaseAuthService } from './FirebaseAuth';
import { SSRAuth } from './SSRAuth';
import { StaticAuth } from './StaticAuth';

export interface IAuth {
    getSyliusToken(): Promise<string | null>;
    signInWithGoogle(): Promise<unknown>;
    signInWithEmail(email: string, password: string): Promise<unknown>;
    resetPasswordByEmail(email: string): Promise<unknown>;
    createUserWithEmailAndPassword(email: string, password: string): Promise<unknown>;
    signInWithIOS(): Promise<unknown>;
    onAuthStateChanged(nextOrObserver: NextOrObserver<User>): Promise<unknown>;
    signOut(): Promise<unknown>;
    confirmPasswordReset(code: string, newPassword: string): Promise<void>;
    verifyEmailAddress(code: string): Promise<void>;
}

export const authServiceFactory = (
    contextOrToken?: Pick<NextPageContext, 'req' | 'res'> | string,
    syliusDefaultToken?: string,
): IAuth => {
    if (contextOrToken) {
        if (typeof contextOrToken === 'string') {
            return new StaticAuth(contextOrToken);
        }

        return new SSRAuth(contextOrToken);
    }

    return new FirebaseAuthService(syliusDefaultToken);
};
