import React, { FC, useState, useRef, useEffect } from 'react';
import {
    TopStyled,
    AccordionWrapperStyled,
    ToggleVisibilityWrapperStyled,
    ExpansionPanelStyled,
} from './Accordion.styled';
import { ChevronUpIcon } from '../icons/ChevronUp/ChevronUp';
import { useSize } from '../../hooks/useSize';

interface AccordionProps {
    title: React.ReactNode;
    withBorder?: boolean;
    children: React.ReactNode;
    defaultOpen?: boolean;
}

export const Accordion: FC<AccordionProps> = ({ title, withBorder, children, defaultOpen = false }) => {
    const [isVisible, setVisibility] = useState(defaultOpen);
    const [initialContentHeight, setInitialContentHeight] = useState(0);
    const target = useRef<HTMLDivElement>(null);
    const size = useSize(target);

    useEffect(() => {
        if (size && size.height) {
            setInitialContentHeight(size.height);
        }
    }, [initialContentHeight, size]);

    return (
        <AccordionWrapperStyled withBorder={withBorder}>
            <TopStyled onClick={() => setVisibility(!isVisible)}>
                <div>{title}</div>
                <ToggleVisibilityWrapperStyled isVisible={isVisible}>
                    <ChevronUpIcon />
                </ToggleVisibilityWrapperStyled>
            </TopStyled>
            <AccordionContent isVisible={isVisible}>{children}</AccordionContent>
        </AccordionWrapperStyled>
    );
};

interface AccordionContentProps {
    isVisible: boolean;
    children: React.ReactNode;
}

export const AccordionContent: FC<AccordionContentProps> = ({ isVisible, children }) => {
    const [initialContentHeight, setInitialContentHeight] = useState(0);
    const target = useRef<HTMLDivElement>(null);
    const size = useSize(target);

    useEffect(() => {
        if (size && size.height) {
            setInitialContentHeight(size.height);
        }
    }, [initialContentHeight, size]);

    return (
        <ExpansionPanelStyled isVisible={isVisible} height={initialContentHeight}>
            <div ref={target}>{children}</div>
        </ExpansionPanelStyled>
    );
};
