import styled from 'styled-components';
import { TOASTR_TRANSITION_TIME } from './ToastrContainer';

export const ToastrDivContainer = styled.div`
    position: fixed;
    width: 100wh;
    height: 100vh;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
`;

interface ToastrBarProps {
    show: boolean;
}

export const ToastrBar = styled.div<ToastrBarProps>`
    position: absolute;
    height: 40px;
    line-height: 40px;
    top: 0;
    transform: translateY(${({ show }) => (show ? '0px' : '-40px')});
    background: #333333;
    color: #ffffff;
    text-align: center;
    width: 100vw;
    transition: transform ${() => TOASTR_TRANSITION_TIME}ms linear;
    z-index: 9999;
    pointer-events: all;
`;
