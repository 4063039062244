import { FC } from 'react';
import { QuantityInput } from '@formify/frontend-components';
import { useStore } from 'react-redux';
import { RootState } from '../../store';
import { changeCartItemQuantity, removeCartItem } from '../../store/project/actions';

interface CartQuantityModifierProps {
    itemId: number;
    quantity: number;
}

export const CartQuantityModifier: FC<CartQuantityModifierProps> = ({ quantity, itemId }) => {
    const { dispatch } = useStore<RootState, any>();

    return (
        <QuantityInput
            decreaseQuantity={() => {
                dispatch(
                    changeCartItemQuantity({
                        cartItemId: itemId,
                        quantity: quantity - 1,
                    }),
                );
            }}
            increaseQuantity={() => {
                dispatch(
                    changeCartItemQuantity({
                        cartItemId: itemId,
                        quantity: quantity + 1,
                    }),
                );
            }}
            deleteItem={() => {
                dispatch(removeCartItem(itemId));
            }}
            quantity={quantity}
            full
        ></QuantityInput>
    );
};
