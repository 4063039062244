import styled from 'styled-components';
import { Color } from '../../../../constants/Color.enum';

interface CardVerticalWrapperProps {
    disabled?: boolean;
    isLoading: boolean;
}

export const CardVerticalWrapper = styled.div<CardVerticalWrapperProps>`
    width: 100%;
    border: 1px solid ${Color.Background};
    border-radius: 16px;
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    display: column;
    flex-direction: row;
    position: relative;
    opacity: ${(props) => (props.disabled ? 0.3 : 'initial')};
    pointer-events: ${({ disabled, isLoading }) => (disabled || isLoading ? 'none' : 'initial')};
`;

export const TopWrapper = styled.div`
    height: 241px;
    background-color: ${Color.Background};
    border-radius: 15px 15px 0 0;
    overflow: hidden;
`;

export const ClearIconWrapperStyled = styled.div`
    cursor: pointer;
    position: absolute;
    padding: 10px;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    display: flex;
    color: ${Color.Secondary};
    z-index: 1;

    &:hover {
        background-color: ${Color.Surface};
        color: ${Color.Primary};
    }
`;

export const LoadingWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const MoreDetailsWrapper = styled.div<CardVerticalWrapperProps>`
    &:hover div {
        color: ${({ disabled, isLoading }) => (disabled || isLoading ? Color.Primary : Color.Secondary)};
    }
`;
