import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../index';
import { CartData } from '../../types/Cart';
import { BlockItem, CountryResponse } from '../../types/Project';
import { UserAddressForm, UserAddressRequestData } from '../../types/Checkout';
import { createAsyncThunkWithShowError } from '../utils/createAsyncThunkWithShowError';
import { getServices } from '../dependency/selectors';

export const fetchOrderData = createAsyncThunkWithShowError<CartData | null, void, ThunkApiConfig>(
    'project/fetchOrderData',
    async (_, { getState }) => {
        const state = getState();
        const { cart } = getServices(state);

        return await cart.getCartData();
    },
);

export const removeCartItem = createAsyncThunkWithShowError<void, number, ThunkApiConfig>(
    'project/removeCartItem',
    async (id, { dispatch, getState }) => {
        const state = getState();
        const { cart } = getServices(state);

        await cart.deleteCartItem(id);
        await dispatch(fetchOrderData());
    },
);

export const changeCartItemQuantity = createAsyncThunkWithShowError<
    CartData | null,
    { cartItemId: number; quantity: number },
    ThunkApiConfig
>('project/changeCartItemQuantity', async ({ cartItemId, quantity }, { dispatch, getState }) => {
    const state = getState();
    const { cart } = getServices(state);

    return cart.changeCartItemQuantity(cartItemId, quantity);
});

export const addDiscountCodeToOrder = createAsyncThunk<void, string, ThunkApiConfig>(
    'project/addDiscountCodeToOrder',
    async (value, { dispatch, getState }) => {
        const state = getState();
        const { cart } = getServices(state);

        await cart.applyCoupon(value);
        await dispatch(fetchOrderData());
    },
);

export const removeDiscountCode = createAsyncThunkWithShowError<void, void, ThunkApiConfig>(
    'project/removeDiscountCode',
    async (_, { dispatch, getState }) => {
        const state = getState();
        const { cart } = getServices(state);

        await cart.deleteDiscountCode();
        await dispatch(fetchOrderData());
    },
);

export const updateUserAddress = createAsyncThunkWithShowError<void, UserAddressForm, ThunkApiConfig>(
    'checkout/updateUserAddressData',
    async (
        {
            email,
            firstName,
            lastName,
            firstNameBilling,
            lastNameBilling,
            country,
            street,
            postCode,
            city,
            countryBilling,
            streetBilling,
            postCodeBilling,
            cityBilling,
            info,
            infoBilling,
            subscribedToNewsletter,
            areSameAddresses,
            floor,
            hasElevator,
            phoneNumber,
            phonePrefix,
        },
        { dispatch, getState },
    ) => {
        const formattedUserData: UserAddressRequestData = {
            email: email,
            subscribedToNewsletter,
            shippingAddress: {
                firstName: firstName,
                lastName: lastName,
                countryCode: country,
                street: street,
                postcode: postCode,
                city: city,
                notes: info,
                floor,
                hasElevator,
                phoneNumber,
                phonePrefix,
            },
            billingAddress: {
                firstName: areSameAddresses ? firstName : firstNameBilling,
                lastName: areSameAddresses ? lastName : lastNameBilling,
                countryCode: areSameAddresses ? country : countryBilling,
                street: areSameAddresses ? street : streetBilling,
                postcode: areSameAddresses ? postCode : postCodeBilling,
                city: areSameAddresses ? city : cityBilling,
                notes: infoBilling,
                phoneNumber,
                phonePrefix,
            },
        };

        const state = getState();
        const { cart } = getServices(state);

        await cart.editUserAddress(formattedUserData);
        await dispatch(fetchOrderData());
    },
);

export const setBlockItems = createAction<BlockItem[]>('project/setBlockItems');

export const setOrderData = createAction<CartData | null>('project/setOrderData');

export const setAvailableCountries = createAction<CountryResponse[]>('project/setAvailableCountries');

export const setAllCountries = createAction<CountryResponse[]>('project/setAllCountries');

export const setLoadingAddingProjectToCartStatus = createAction<boolean>('project/setLoadingAddingProjectToCartStatus');

export const clearDiscountCodeData = createAction<void>('project/clearDiscountCodeData');

export const setCountryForSavingProject = createAction<string>('project/setCountryForSavingProject');

export const showLoaderOnCheckoutAction = createAction<boolean>('project/showLoaderOnCheckout');

export const setUpdateOrderByAdmin = createAction<{
    token: string;
    orderItemId: string;
}>('project/setUpdateOrderByAdmin');
