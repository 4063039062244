import styled from 'styled-components';
import { Color } from '../../constants/Color.enum';
import { TextInputStandardStyled } from '../atoms/TextInput/TextInput.styled';

interface TextInputButtonProps {
    isInputHighlighted: boolean;
    isLoading?: boolean;
}

export const ButtonWrapperStyled = styled.button`
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    padding: 24px;
    border-radius: 0 10px 10px 0px;
    user-select: none;
    height: 64px;
    cursor: pointer;
    color: ${Color.Black};
`;

export const TextInputWithButtonWrapper = styled.div<TextInputButtonProps>`
    pointer-events: ${(props) => props.isLoading && 'none'};

    &:hover {
        ${ButtonWrapperStyled} {
            background-color: ${Color.Black};

            div {
                color: ${Color.Surface};
            }
        }
    }

    ${TextInputStandardStyled} {
        border: ${(props) => props.isInputHighlighted && `1px solid ${Color.Primary}`};
    }
`;
