import React, { FC } from 'react';

interface IconProps {
    color?: string;
}

export const ChevronLeftIcon: FC<IconProps> = ({ color = 'currentColor' }) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4717 3.82824C11.8427 4.19923 11.8427 4.80074 11.4717 5.17174L7.64348 8.99999L11.4717 12.8282C11.8427 13.1992 11.8427 13.8007 11.4717 14.1717C11.1007 14.5427 10.4992 14.5427 10.1282 14.1717L5.62822 9.67174C5.45006 9.49358 5.34998 9.25194 5.34998 8.99999C5.34998 8.74803 5.45006 8.5064 5.62822 8.32824L10.1282 3.82824C10.4992 3.45724 11.1007 3.45724 11.4717 3.82824Z"
            fill={color}
        />
    </svg>
);
