import React, { FC } from 'react';
import { Color } from '../../../../constants/Color.enum';
import { LinkS } from '@formify/frontend-components';
import { Message } from '../Message';

interface SuccessMessageProps {
    successMessage?: string;
    successValue?: string;
    dataTestId?: string;
}

export const SuccessMessage: FC<SuccessMessageProps> = ({ successMessage, successValue, dataTestId }) => (
    <div className="mt-8 flex items-center">
        <Message success message={successMessage} dataTestId={dataTestId} />
        {successValue && (
            <LinkS color={Color.Input_Success} fontWeight={500}>
                <span className="ml-4">{successValue}</span>
            </LinkS>
        )}
    </div>
);
