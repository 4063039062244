import { useMemo } from 'react';
import Breakpoint from '../constants/Breakpoint.enum';
import { useWindowWidth } from './useWindowWidth';

type Breakpoints = {
    isMobile: boolean;
    isTablet: boolean;
};

export const useBreakpoints = (): Breakpoints => {
    const windowWidth = useWindowWidth();
    const isMobile = useMemo(() => windowWidth <= parseInt(Breakpoint.Tablet, 10), [windowWidth]);
    const isTablet = useMemo(() => windowWidth <= parseInt(Breakpoint.Desktop, 10), [windowWidth]);

    return {
        isMobile,
        isTablet,
    };
};
