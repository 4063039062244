import { LazyMotion, domAnimation, AnimatePresence, m } from 'framer-motion';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { PageAnimation } from '../../constants/animations';
import { Color } from '../../constants/Color.enum';

type WraperProps = {
    isVisible?: boolean;
};

const AppWrapperStyled = styled.div<WraperProps>`
    --position: -100vh;

    .framer-motion:after {
        top: 0;
        content: '';
        position: fixed;
        display: ${(props) => (props.isVisible ? 'flex' : 'none')};
        background-color: ${Color.Surface};
        width: 100vw;
        height: 100vh;
        transform: translateY(var(--position));
    }
`;

interface AnimationProviderProps {
    animation: PageAnimation | null;
    children: React.ReactNode;
}

const AnimationProvider: React.FC<AnimationProviderProps> = ({ animation, children }) => {
    const router = useRouter();

    return (
        <LazyMotion features={domAnimation}>
            <AnimatePresence exitBeforeEnter>
                <AppWrapperStyled isVisible={animation?.isVisible} key={router.pathname}>
                    <m.div
                        className="framer-motion"
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={animation?.variants}
                        transition={animation?.transition}
                    >
                        {children}
                    </m.div>
                </AppWrapperStyled>
            </AnimatePresence>
        </LazyMotion>
    );
};

export default AnimationProvider;
