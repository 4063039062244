import { useRouter } from 'next/router';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import 'react-day-picker/dist/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import AnimationProvider from '../components/AnimationProvider/AnimationProvider';
import { CartSidePanelProvider } from '../components/CartSidePanel/CartSidePanelProvider';
import { ChannelProvider } from '../components/Channel/ChannelProvider';
import { CookiesConsent } from '../components/CookiesConsent/CookiesConsent';
import { CurrencyProvider } from '../components/Currency/CurrencyProvider';
import { HeaderDependenciesManager } from '../components/HeaderDependenciesManager/HeaderDependenciesManager';
import { HistoryProvider } from '../components/History/HistoryProvider';
import { OgMetaTags } from '../components/OgMetaTags/OgMetaTags';
import { ToastrContainer } from '../components/ToastrContainer/ToastrContainer';
import { LanguageProvider } from '../components/Translations/LanguageProvider';
import { getRootServices } from '../services';
import { setPage } from '../services/analytics/analytics';
import { authServiceFactory } from '../services/auth/authServiceFactory';
import { getStore } from '../store';
import { setServices } from '../store/dependency/actions';
import '../styles/carousel.css';
import '../styles/globals.css';
import { AppProps } from '../types/common';
import './style.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import Head from 'next/head';
import { MeasureUnitProvider } from '../components/MeasureUnit/MeasureUnitProvider';

// import dynamic from 'next/dynamic';
// const AnimationProvider = dynamic(() => import('../components/AnimationProvider/AnimationProvider'));

const theme = {};

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const store = useMemo(() => getStore(pageProps.initialReduxActions || []), [pageProps.initialReduxActions]);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/sw.js').catch((error) => {
                // eslint-disable-next-line no-console
                console.error('Service Worker registration failed:', error);
            });
        }
    }, []);

    useEffect(() => {
        const handleRouteChange = () => {
            setPage(document.title, location.href);
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    useLayoutEffect(() => {
        if (typeof document !== 'undefined') {
            const auth = authServiceFactory(undefined, pageProps.token || undefined);
            const services = getRootServices(auth);

            store.dispatch(setServices(services));
        }
    }, [pageProps.token, store]);

    return (
        <ThemeProvider theme={theme}>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Head>
            <OgMetaTags />
            <HistoryProvider>
                <ChannelProvider>
                    <AnimationProvider animation={pageProps.animation}>
                        <Provider store={store}>
                            <LanguageProvider>
                                <MeasureUnitProvider>
                                    <OgMetaTags />
                                    <HeaderDependenciesManager />
                                    <CurrencyProvider>
                                        <CartSidePanelProvider>
                                            <ToastrContainer />
                                            {/* @ts-ignore */}
                                            <Component {...pageProps} />
                                            <CookiesConsent />
                                        </CartSidePanelProvider>
                                    </CurrencyProvider>
                                </MeasureUnitProvider>
                            </LanguageProvider>
                        </Provider>
                    </AnimationProvider>
                </ChannelProvider>
            </HistoryProvider>
        </ThemeProvider>
    );
}

export default MyApp;
