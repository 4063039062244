import React, { FC, useState, useEffect, useRef, InputHTMLAttributes } from 'react';
import { ClearIcon } from '../../icons/Clear/Clear';
import {
    TextInputStandardStyled,
    TextAreaStyled,
    TextInputWrapperStyled,
    StandardLabelStyled,
    TextAreaLabelStyled,
    IconRightWrapperStyled,
    IconLeftWrapperStyled,
    TextAreaGrabberStyled,
    GrabberPullTabStyled,
    TAXTAREA_LABEL_MARGIN,
} from './TextInput.styled';
import { useFormContext } from 'react-hook-form';
import { LoadingIcon } from '../../icons/Loading/Loading';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    disabled?: boolean;
    blocked?: boolean;
    isLoading?: boolean;
    error?: boolean;
    success?: boolean;
    value?: string;
    label?: string;
    icon?: React.ReactNode;
    valueAsNumber?: boolean;
    textarea?: boolean;
    onClear?: () => void;
    handleButtonClick?: () => void;
    responsiveWidth?: boolean;
    name: string;
}

function checkIsEmpty(value: number | string | null | undefined) {
    if (typeof value === 'string') {
        return value === '';
    }
    if (typeof value === 'number') {
        return value.toString() === '';
    }

    return value === null || value === undefined;
}

export const TextInput: FC<TextInputProps> = ({
    label,
    icon,
    value,
    disabled,
    blocked,
    error,
    success,
    textarea,
    onChange,
    onClear,
    children,
    responsiveWidth = false,
    name,
    isLoading,
    valueAsNumber = false,
    ...otherInputProps
}) => {
    const [showTextAreaLabel, setTextareaLabelVisibility] = useState<boolean>(true);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const formMethods = useFormContext();
    const finalValue = formMethods ? formMethods.watch(name) ?? '' : value;
    const isEmpty = checkIsEmpty(finalValue);

    const inputControlAttributes =
        formMethods && !value
            ? formMethods.register(name, {
                  valueAsNumber,
              })
            : { onChange, value };

    useEffect(() => {
        const ref = textareaRef.current;

        if (ref) {
            const onScroll = () => {
                setTextareaLabelVisibility(ref.scrollTop < TAXTAREA_LABEL_MARGIN / 2);
            };

            ref.addEventListener('scroll', onScroll, { passive: true });

            return () => {
                ref.removeEventListener('scroll', onScroll);
            };
        }
    }, []);

    return (
        <>
            <TextInputWrapperStyled responsiveWidth={responsiveWidth}>
                {icon && <IconLeftWrapperStyled>{icon}</IconLeftWrapperStyled>}
                {textarea ? (
                    <div className="flex">
                        <TextAreaStyled
                            disabled={disabled || isLoading}
                            isIcon={Boolean(icon)}
                            error={error}
                            success={success}
                            isEmpty={isEmpty}
                            ref={textareaRef}
                            value={finalValue}
                            {...otherInputProps}
                            {...inputControlAttributes}
                        />
                        <TextAreaLabelStyled
                            disabled={disabled || isLoading}
                            error={error}
                            success={success}
                            show={showTextAreaLabel}
                            isEmpty={isEmpty}
                        >
                            {label}
                        </TextAreaLabelStyled>
                        <TextAreaGrabberStyled>
                            <GrabberPullTabStyled />
                        </TextAreaGrabberStyled>
                    </div>
                ) : (
                    <TextInputStandardStyled
                        disabled={disabled || isLoading}
                        blocked={blocked}
                        isIcon={Boolean(icon)}
                        error={error}
                        success={success}
                        isEmpty={isEmpty}
                        value={finalValue}
                        {...otherInputProps}
                        {...inputControlAttributes}
                    />
                )}
                {!textarea && (
                    <StandardLabelStyled
                        disabled={disabled || isLoading}
                        error={error}
                        success={success}
                        isEmpty={isEmpty}
                        isIcon={Boolean(icon)}
                    >
                        {label}
                    </StandardLabelStyled>
                )}
                {!isLoading && !isEmpty && onClear && (
                    <IconRightWrapperStyled textarea={textarea} onClick={onClear}>
                        <ClearIcon />
                    </IconRightWrapperStyled>
                )}
                {isLoading && (
                    <IconRightWrapperStyled disabled>
                        <LoadingIcon />
                    </IconRightWrapperStyled>
                )}
                {children}
            </TextInputWrapperStyled>
        </>
    );
};
