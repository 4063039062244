export enum Breakpoint {
    Mobile = '420px',
    Tablet = '768px',
    Desktop = '1024px',
    LargeDesktop = '1440px',
    ExtraLargeDesktop = '1920px',
}

// TODO delete default export
export default Breakpoint;
