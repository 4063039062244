import Head from 'next/head';
import { useEnv } from '../../hooks/useEnv';
import { useChannel } from '../Channel/ChannelProvider';

export const HeaderDependenciesManager = () => {
    const GTM_TRACKING_ID = useEnv('GTM_TRACKING_ID');
    const HOT_JAR_TRACKING_ID = useEnv('HOT_JAR_TRACKING_ID');
    const NEXT_PUBLIC_ENVIRONMENT_NAME = useEnv('NEXT_PUBLIC_ENVIRONMENT_NAME');
    const channel = useChannel();

    return (
        <Head>
            {NEXT_PUBLIC_ENVIRONMENT_NAME !== 'PROD' && <meta name="robots" content="noindex" />}

            {GTM_TRACKING_ID && (
                <script
                    src={`https://www.googletagmanager.com/gtag/js?id=${GTM_TRACKING_ID}&l=dataLayerAnalytics`}
                    defer
                    async
                ></script>
            )}

            {GTM_TRACKING_ID && (
                <script
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayerAnalytics = window.dataLayerAnalytics || [];
                    function gtag(){dataLayerAnalytics.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GTM_TRACKING_ID}');`,
                    }}
                />
            )}

            {HOT_JAR_TRACKING_ID && (
                <script
                    dangerouslySetInnerHTML={{
                        __html: `(function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:${HOT_JAR_TRACKING_ID},hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
                    }}
                />
            )}
            {channel.favicon && <link rel="icon" href={channel.favicon} />}
        </Head>
    );
};
