import React, { FC, useEffect, useState } from 'react';
import { CartItem } from '../../../../../../types/Cart';
import { getProjectImageByType } from '../../../../../../services/project/project';
import { ScrollableContentInSidePanel } from '../../../../../Sidebar/components/ScrollableContentInSidePanel/ScrollableContentInSidePanel';
import { Button } from '@formify/frontend-components';
import { CardHorizontal } from '../../../../../Card/components/CardHorizontal/CardHorizontal';
import { getProjectIdFromProject } from '../../utils';
import { formatMeasurements } from '../../../../../Card/utils';
import { getWardrobeSize, useProjectDetailsById } from '../../../../../../hooks/useProjectDetailsById';
import { ProjectDetails } from '../../../../../ProjectDetails/ProjectDetails';
import { useLanguage } from '../../../../../Translations/LanguageProvider';
import { Tr } from '../../../../../Translations/Tr';
import { useSelector } from 'react-redux';
import { getAllProducts } from '../../../../../../services/nodes/wardrobe/wardrobe';
import { Measure } from '@formify/projects-state-manager';

interface SummaryDetailsProps {
    onClose: () => void;
    item: CartItem;
}

export const SummaryDetails: FC<SummaryDetailsProps> = ({ onClose, item }) => {
    const { t } = useLanguage();

    const [wardrobeParams, setWardrobeParams] = useState<{
        width: Measure;
        height: Measure;
        depth: Measure;
    } | null>(null);

    const projectId = getProjectIdFromProject(item);
    const { grouped, project } = useProjectDetailsById(projectId);
    const wardrobeImg = getProjectImageByType(item.images, 'vertical-thumb');
    const productCode = project?.getProduct().code || 'wardrobe';
    const products = useSelector(getAllProducts);

    useEffect(() => {
        if (project) {
            setWardrobeParams(getWardrobeSize(project));
        }
    }, [project]);

    const { currentLanguage } = useLanguage();

    return (
        <ScrollableContentInSidePanel
            dataTestId="closet_details"
            title={t('Closet details')}
            sticky={
                <>
                    <Button
                        variant="primary"
                        onClick={onClose}
                        className="ml-auto"
                        dataTestId="footer-sidebar-back-button"
                        full
                    >
                        <Tr labelKey="close_summary_detail_button" defaultValue="Back" />
                    </Button>
                </>
            }
        >
            {wardrobeParams && wardrobeImg && (
                <div className="mb-20">
                    <CardHorizontal
                        dataTestId={productCode}
                        subtitle={products?.[productCode].name[currentLanguage] || ''}
                        title={formatMeasurements(wardrobeParams, t)}
                        imgPath={wardrobeImg}
                    />
                </div>
            )}

            <ProjectDetails grouped={grouped} />
        </ScrollableContentInSidePanel>
    );
};
