import React, { FC } from 'react';
import { DynamicWrapperStyled, DynamicWrapperStyledProvider } from './WithDynamicOffset.styled';
import { useHeaderOffset } from '../../hooks/useHeaderOffset';

export const WithDynamicOffset: FC<{ children: React.ReactNode }> = ({ children }) => (
    <DynamicOffsetProvider>
        <DynamicWrapperStyled>{children}</DynamicWrapperStyled>
    </DynamicOffsetProvider>
);

const DynamicOffsetProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const offset = useHeaderOffset();

    return <DynamicWrapperStyledProvider offset={offset}>{children}</DynamicWrapperStyledProvider>;
};
