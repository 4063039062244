import { IAuth } from './authServiceFactory';

export class StaticAuth implements IAuth {
    private token: string | null;

    constructor(token: string | null) {
        this.token = token;
    }

    async getSyliusToken(): Promise<string | null> {
        return this.token;
    }

    async resetPasswordByEmail(): Promise<unknown> {
        throw new Error('SSR Auth not support reset password');
    }

    async signInWithGoogle() {
        throw new Error('Static Auth not support google auth');
    }
    async signInWithIOS() {
        throw new Error('Static Auth not support iOS auth');
    }
    async signInWithEmail() {
        throw new Error('Static Auth not support email auth');
    }
    async createUserWithEmailAndPassword() {
        throw new Error('Static Auth not support email auth');
    }
    async confirmPasswordReset() {
        throw new Error('Static Auth not support email auth');
    }
    async verifyEmailAddress() {
        throw new Error('Static Auth not support email auth');
    }
    async onAuthStateChanged() {}
    async signOut() {
        this.token = null;
    }
}
