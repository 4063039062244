import { useEffect } from 'react';

export function useLockEffect<T>(callback: () => Promise<T>, valueHandler: (value: T) => void) {
    useEffect(() => {
        let lock = false;

        callback().then((data) => {
            if (!lock) {
                valueHandler(data);
            }
        });

        return () => {
            lock = true;
        };
    }, [callback, valueHandler]);
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export function useLockEffectLazy<T>(callback: () => Promise<T>, valueHandler: (value: T) => void, timeout = 100) {
    useEffect(() => {
        let lock = false;

        sleep(timeout).then(() => {
            if (!lock) {
                callback().then((data) => {
                    if (!lock) {
                        valueHandler(data);
                    }
                });
            }
        });

        return () => {
            lock = true;
        };
    }, [callback, timeout, valueHandler]);
}
