import { createReducer } from '@reduxjs/toolkit';
import { RootServices } from '../../services';
import { setServices } from './actions';

interface DependencyState {
    services: RootServices | null;
}

const initialState: DependencyState = {
    services: null,
};

const dependency = createReducer(initialState, ({ addCase }) => {
    addCase(setServices, (state, { payload }) => {
        state.services = payload;
    });
});

export default dependency;
