import { prepareDataForFormifyWardrobeState, prepareFormifyWardrobeState } from '@formify/projects-state-manager';
import { getApiByCurrentHost } from '../env/env';
import { useEffect, useState } from 'react';
import { AllColumnLayouts } from '@formify/projects-state-manager/dist/formify-wardrobe/services/layouts';

let cache: ReturnType<typeof prepareDataForFormifyWardrobeState> | null = null;

export function getDataForWardrobeState() {
    if (cache) {
        return cache;
    }
    const { channel, host } = getApiByCurrentHost();

    cache = prepareDataForFormifyWardrobeState({
        channel,
        domain: host,
    });

    return cache;
}

export async function createWardrobeState(debug = false) {
    const { channel, host } = getApiByCurrentHost();

    return prepareFormifyWardrobeState(
        await getDataForWardrobeState(),
        {
            channel,
            domain: host,
        },
        debug,
    );
}

export function useLayouts() {
    const [layouts, setLayout] = useState<AllColumnLayouts>({});

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        getDataForWardrobeState().then(([, layouts]) => {
            setLayout(layouts);
        });
    }, []);

    return layouts;
}
