import { Measure } from '@formify/projects-state-manager';
import { CartCustomOptionsValue, CartItem } from '../../types/Cart';
import { WardrobeMeasurements } from '../../types/Product';
import { LanguageContextValues } from '../Translations/LanguageProvider';

const mapOptionToLabel =
    (unit: 'MM' | 'DIN') =>
    (
        acc: Partial<WardrobeMeasurements>,
        { threeDParameter, value }: CartCustomOptionsValue,
    ): Partial<WardrobeMeasurements> => {
        if (threeDParameter.genericCode === 'HEIGHT') {
            return { ...acc, height: new Measure(Number(value), unit) };
        }
        if (threeDParameter.genericCode === 'WIDTH') {
            return { ...acc, width: new Measure(Number(value), unit) };
        }
        if (threeDParameter.genericCode === 'DEPTH') {
            return { ...acc, depth: new Measure(Number(value), unit) };
        }

        return acc;
    };

export const formatMeasurements = (
    { width, height, depth }: Partial<WardrobeMeasurements>,
    t: LanguageContextValues['t'],
) => {
    const unit = width?.getUnit() || height?.getUnit() || depth?.getUnit() || 'MM';
    const parts: string[] = [];

    if (width instanceof Measure) {
        parts.push(
            `${t('short_width', 'W ').replace(' ', '')}${
                unit === 'MM' ? width.getCentimeters().toFixed(0) : width.getInches().toFixed(1)
            }`,
        );
    }

    if (height instanceof Measure) {
        parts.push(
            `${t('short_height', 'H ').replace(' ', '')}${
                unit === 'MM' ? height.getCentimeters().toFixed(0) : height.getInches().toFixed(1)
            }`,
        );
    }

    if (depth instanceof Measure) {
        parts.push(
            `${t('short_depth', 'D ').replace(' ', '')}${
                unit === 'MM' ? depth.getCentimeters().toFixed(0) : depth.getInches().toFixed(1)
            }`,
        );
    }

    if (parts.length === 0) {
        return '';
    }

    return `${parts.join(' / ')} ${unit === 'MM' ? 'cm' : 'in'}`;
};

export const getCartItemSubtitle = (item: CartItem, t: LanguageContextValues['t']) => {
    const measurements = item.threeDParametersValues.reduce<Partial<WardrobeMeasurements>>(
        mapOptionToLabel(item.project.unit),
        {} as Partial<WardrobeMeasurements>,
    );

    return formatMeasurements(measurements, t);
};
