import { useEffect, useState } from 'react';

export const useWindowWidth = (): number => {
    const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleWindowSizeChange = () => {
                setWidth(window.innerWidth);
            };

            window.addEventListener('resize', handleWindowSizeChange);

            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            };
        }
    }, [setWidth]);

    useEffect(() => {
        if (typeof window !== 'undefined' && width !== window.innerWidth) {
            setWidth(window.innerWidth);
        }
    }, [setWidth, width]);

    return width;
};
