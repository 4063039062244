import React, { FC } from 'react';

export const ChevronRightIcon: FC = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.52827 14.1718C6.15727 13.8008 6.15727 13.1993 6.52827 12.8283L10.3565 9.00001L6.52827 5.17176C6.15727 4.80077 6.15727 4.19926 6.52827 3.82826C6.89927 3.45726 7.50078 3.45726 7.87177 3.82826L12.3718 8.32826C12.5499 8.50642 12.65 8.74806 12.65 9.00001C12.65 9.25197 12.5499 9.4936 12.3718 9.67176L7.87178 14.1718C7.50078 14.5428 6.89927 14.5428 6.52827 14.1718Z"
            fill="currentColor"
        />
    </svg>
);
