import { RefObject, useEffect } from 'react';

type PointerEvents = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: (event: PointerEvents) => void,
): void {
    useEffect(() => {
        const listener = (event: PointerEvents) => {
            const el = ref?.current;
            const target = event.target;

            if (!el || (target instanceof Node && el.contains(target))) {
                return;
            }

            handler(event);
        };

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        };
    }, [ref, handler]);
}

export { useOnClickOutside };
