import React, { useRef } from 'react';
import { CarouselContainerStyled, CarouselWrapper, OverlayStyled } from '../../../Sidebar/Sidebar.styled';
import { Carousel } from 'react-responsive-carousel';
import { WithDynamicOffset } from '../../../WithDynamicOffset/WithDynamicOffset';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';

export enum CarouselIndexes {
    Main = 0,
    Details = 1,
}

interface SliderWithDetailsProps {
    selectedSlideIndex: CarouselIndexes;
    isOpen: boolean;
    overlay?: boolean;
    children: React.ReactNode;
    details?: React.ReactNode;
    onClose?: () => void;
    zIndex?: number;
    isMobileFullWidth?: boolean;
}

export const SliderWithDetails = ({
    selectedSlideIndex,
    isOpen,
    children,
    details,
    overlay = false,
    onClose,
    zIndex,
    isMobileFullWidth = false,
}: SliderWithDetailsProps) => {
    const carouselRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(carouselRef, () => isOpen && onClose && !overlay && onClose());

    return (
        <WithDynamicOffset>
            {isOpen && overlay && <OverlayStyled onClick={onClose} />}
            <CarouselContainerStyled
                zIndex={zIndex}
                isOpen={isOpen}
                ref={carouselRef}
                isMobileFullWidth={isMobileFullWidth}
            >
                <Carousel
                    selectedItem={selectedSlideIndex}
                    showThumbs={false}
                    swipeable={false}
                    showArrows={false}
                    showStatus={false}
                    showIndicators={false}
                >
                    <CarouselWrapper>{children}</CarouselWrapper>
                    <CarouselWrapper>{details}</CarouselWrapper>
                </Carousel>
            </CarouselContainerStyled>
        </WithDynamicOffset>
    );
};
