import React from 'react';
import { TitleWrapperStyled, StickyFooterStyled, SlideWrapperStyled } from '../../Sidebar.styled';
import { LinkM } from '@formify/frontend-components';
import { Container } from './ScrollableContentInSidePanel.styled';
import { Button } from '@formify/frontend-components';
import { ChevronLeftIcon } from '../../../icons/ChevronLeft/ChevronLeft';
import styled from 'styled-components';

interface ScrollableContentInSidePanelProp {
    title?: string;
    children: React.ReactNode;
    sticky?: React.ReactNode;
    dataTestId?: string;
    onClickBack?: () => void;
    rightContent?: React.ReactNode;
}

const IconWrapperLeft = styled.div`
    cursor: pointer;
    position: absolute;
    z-index: 100;
    left: 22px;
`;

const WrapperRight = styled.div`
    cursor: pointer;
    position: absolute;
    z-index: 100;
    right: 22px;
`;

export const ScrollableContentInSidePanel: React.FC<ScrollableContentInSidePanelProp> = ({
    title,
    children,
    sticky,
    onClickBack,
    dataTestId,
    rightContent,
}) => (
    <Container data-test="content" data-test-id={dataTestId}>
        <div className="flex w-full flex-col overflow-auto">
            <div>
                {title && (
                    <TitleWrapperStyled>
                        {onClickBack && (
                            <IconWrapperLeft>
                                <Button
                                    variant="outlined-white"
                                    icon={<ChevronLeftIcon />}
                                    onClick={onClickBack}
                                ></Button>
                            </IconWrapperLeft>
                        )}
                        <LinkM>{title}</LinkM>
                        {rightContent && <WrapperRight>{rightContent}</WrapperRight>}
                    </TitleWrapperStyled>
                )}
            </div>
            <SlideWrapperStyled>{children}</SlideWrapperStyled>
        </div>
        {sticky && <StickyFooterStyled>{sticky}</StickyFooterStyled>}
    </Container>
);
