import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect, useState } from 'react';
import { useLanguage } from './LanguageProvider';

interface TrProps {
    labelKey: string;
    defaultValue: string;
    variables?: { [key: string]: string | number };
}
export const Tr = ({ labelKey, defaultValue, variables }: TrProps) => {
    const value = useTr(labelKey, defaultValue, variables);

    return <span>{value}</span>;
};

export const useTr = (labelKey: string, defaultValue: string, variables?: { [key: string]: string | number }) => {
    const { currentLanguage, t } = useLanguage();
    const service = useSelector((state: RootState) => state.dependency.services?.translation);
    const [value, setValue] = useState<string>(() => t(labelKey, defaultValue));

    useEffect(() => {
        if (!service) {
            return;
        }
        service.getLabel(currentLanguage, labelKey, defaultValue).then((value) => {
            if (variables) {
                setValue(service.applyVariables(value, variables));
            } else {
                setValue(value);
            }
        });
    }, [currentLanguage, defaultValue, labelKey, service, variables]);

    return value;
};
