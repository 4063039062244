import { FirebaseApp, initializeApp } from 'firebase/app';
import { getPublicEnv } from '../env/env';

const app: Promise<FirebaseApp> | null = null;

export const getDefaultFirebaseApp = (): Promise<FirebaseApp> => {
    if (app) {
        return app;
    }
    const createdApp = new Promise<FirebaseApp>((resolve) => {
        (async () => {
            const firebaseConfig = {
                apiKey: await getPublicEnv('NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY'),
                authDomain: await getPublicEnv('NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN'),
                projectId: await getPublicEnv('FIREBASE_PROJECT_ID'),
                appId: await getPublicEnv('NEXT_PUBLIC_FIREBASE_APP_ID'),
            };

            const createdApp = initializeApp(firebaseConfig);

            resolve(createdApp);
        })();
    });

    return createdApp;
};
