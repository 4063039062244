import React, { FC } from 'react';
import { EmptyCartWrapper } from './EmptyCart.styled';
import { LinkS } from '@formify/frontend-components';
import { TextS } from '@formify/frontend-components';
import { Button } from '@formify/frontend-components';

interface EmptyCartProps {
    onClose: () => void;
}

export const EmptyCart: FC<EmptyCartProps> = ({ onClose }) => (
    <EmptyCartWrapper>
        <LinkS className="mb-16">Your cart is empty</LinkS>
        <TextS className="mb-32 text-center">Looks like you have not made your choice yet...</TextS>
        <Button variant="primary" onClick={onClose}>
            Close
        </Button>
    </EmptyCartWrapper>
);
