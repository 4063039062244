import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect, useState } from 'react';
import { useLanguage } from './LanguageProvider';

interface TrPluralProps<K extends string> {
    labelKey: string;
    defaultValue: {
        [key in Intl.LDMLPluralRule]?: string;
    } & {
        other: string;
    };
    amountKey: K;
    variables?: { [key in K]: string | number };
}

export const TrPlural = <K extends string>({ labelKey, defaultValue, amountKey, variables }: TrPluralProps<K>) => {
    const value = useTrPlural(labelKey, defaultValue, amountKey, variables);

    return <span>{value}</span>;
};

const useTrPlural = <K extends string>(
    labelKey: string,
    defaultValue: {
        [key in Intl.LDMLPluralRule]?: string;
    } & {
        other: string;
    },
    amountKey: K,
    variables?: { [key in K]: string | number },
) => {
    const { currentLanguage, pluralRules, t } = useLanguage();
    const service = useSelector((state: RootState) => state.dependency.services?.translation);
    const [value, setValue] = useState<string>(() => t(`${labelKey}.other`, defaultValue.other));

    useEffect(() => {
        if (!service) {
            return;
        }
        const number = variables?.[amountKey];

        if (typeof number !== 'number') {
            throw new Error(`variables.${amountKey} must be a number`);
        }

        const pluralRuleName = pluralRules(variables?.[amountKey] as number);

        service
            .getLabel(currentLanguage, `${labelKey}.${pluralRuleName}`, defaultValue[pluralRuleName] || '')
            .then((value) => {
                if (variables) {
                    setValue(service.applyVariables(value, variables));
                }
            });
    }, [currentLanguage, defaultValue, labelKey, amountKey, pluralRules, service, variables]);

    return value;
};
