import styled from 'styled-components';

interface DynamicWrapperProps {
    offset?: string;
}

export const DynamicWrapperStyledProvider = styled.div<DynamicWrapperProps>`
    --dynamic-offset: ${(props) => props.offset};
`;

export const DynamicWrapperStyled = styled.div`
    top: var(--dynamic-offset);
    position: relative;
    transition: all 0.5s;
    height: calc(100% - var(--dynamic-offset));
`;
