import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { popToastr } from '../../store/toastr/actions';
import { Toastr } from '../../store/toastr/reducer';
import { getToastrToShow } from '../../store/toastr/selectors';
import { ToastrBar, ToastrDivContainer } from './ToastrContainer.styled';

const TOASTR_SHOW_TIME = 4000;

export const TOASTR_TRANSITION_TIME = 400;

export const ToastrContainer = () => {
    const toastr = useSelector(getToastrToShow);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [currentToastr, setCurrentToastr] = useState<Toastr | null>(null);

    useEffect(() => {
        if (toastr) {
            if (currentToastr === null) {
                setShow(true);
                setCurrentToastr(toastr);
            }
            const timer = setTimeout(() => {
                dispatch(popToastr());
                setShow(false);
            }, TOASTR_SHOW_TIME);

            return () => clearTimeout(timer);
        }
    }, [currentToastr, dispatch, toastr]);

    useEffect(() => {
        if (show === false) {
            const timer = setTimeout(() => {
                // delete after animate ending
                setCurrentToastr(null);
            }, TOASTR_TRANSITION_TIME);

            return () => clearTimeout(timer);
        }
    }, [show]);

    return (
        <ToastrDivContainer>
            <ToastrBar
                show={show}
                onClick={() => {
                    dispatch(popToastr());
                    setShow(false);
                }}
            >
                {currentToastr && <span>{currentToastr.description}</span>}
            </ToastrBar>
        </ToastrDivContainer>
    );
};
