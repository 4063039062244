import { getPublicEnv } from '../env/env';
import {
    CreateCartResponse,
    CartData,
    CartItem,
    CartCustomOptionsValue,
    OrderPromotionData,
    PromotionAction,
    Promotion,
    CartChildrenItem,
    ProjectImage,
    SelectedInstallationPartnerData,
    InstallationPartnerAssistanceType,
} from './../../types/Cart';
import {
    isObjectWithKey,
    getGenericArray,
    getNumber,
    isGenericArray,
    getGenericValue,
    getString,
    isNumber,
    isString,
} from '../validateData';
import {
    Address,
    Customer,
    UserAddressRequestData,
    Payment,
    Shipment,
    CompletePaymentRequestData,
    SelectPaymentMethodResponse,
    PaymentMethodsResponse,
    GatewayConfig,
    ElevatorAddress,
} from '../../types/Checkout';
import { SaveCartRequestParam, SaveCartResponse } from '../../types/Project';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import { FetchService } from '../fetch/fetch';
import { OptionsType } from 'cookies-next/lib/types';
import { isInstallationPartnerData } from '../installationPartners/InstallationPartners';
import { DEFAULT_LOCALE_CODE } from '../../store/project/selectors';
import { isProductCodes } from '../products/domain/ProductCodes';

function isCartItem(data: unknown): data is CartItem {
    return (
        isObjectWithKey(data) &&
        isGenericArray(data.images, isImage) &&
        isObjectWithKey(data.project) &&
        typeof data.project.id === 'string' &&
        typeof data.project.unit === 'string' &&
        (data.project.unit === 'MM' || data.project.unit === 'DIN') &&
        isCartChildrenItem(data)
    );
}

function isPromotion(data: unknown): data is Promotion {
    return isObjectWithKey(data) && isGenericArray(data.actions, isPromotionAction) && typeof data.name === 'string';
}

function isOrderPromotionData(data: unknown): data is OrderPromotionData {
    return isObjectWithKey(data) && typeof data.code === 'string' && isPromotion(data.promotion);
}

function isAddress(data: unknown): data is Address {
    return (
        isObjectWithKey(data) &&
        typeof data.firstName === 'string' &&
        typeof data.lastName === 'string' &&
        typeof data.countryCode === 'string' &&
        typeof data.street === 'string' &&
        typeof data.city === 'string' &&
        typeof data.notes === 'string' &&
        typeof data.postcode === 'string'
    );
}

function isAddressWithElevator(data: unknown): data is Address & ElevatorAddress {
    return (
        isObjectWithKey(data) &&
        typeof data.hasElevator === 'boolean' &&
        typeof data.floor === 'number' &&
        isAddress(data)
    );
}

function isCustomer(data: unknown): data is Customer {
    return isObjectWithKey(data) && typeof data.email === 'string' && typeof data.subscribedToNewsletter === 'boolean';
}

function isPayment(data: unknown): data is Payment {
    return (
        isObjectWithKey(data) &&
        typeof data.id === 'number' &&
        isObjectWithKey(data.method) &&
        typeof data.method.name === 'string' &&
        (typeof data.stripeClientSecret === 'string' || data.stripeClientSecret === undefined)
    );
}

function isSelectMethodPayment(data: unknown): data is Payment {
    return isPayment(data) && typeof data.stripeClientSecret === 'string';
}

function isShipment(data: unknown): data is Shipment {
    return isObjectWithKey(data) && typeof data.id === 'number';
}

function isCartChildrenItem(data: unknown): data is CartChildrenItem {
    return (
        isObjectWithKey(data) &&
        typeof data.productName === 'string' &&
        typeof data.productCode === 'string' &&
        isProductCodes(data.productCode) &&
        typeof data.id === 'number' &&
        typeof data.quantity === 'number' &&
        typeof data.unitPrice === 'number' &&
        typeof data.fullDiscountedUnitPrice === 'number' &&
        typeof data.total === 'number' &&
        isGenericArray(data.threeDParametersValues, isCartCustomOptionsValue)
    );
}

function isImage(data: unknown): data is ProjectImage {
    return (
        isObjectWithKey(data) &&
        typeof data.id === 'number' &&
        typeof data.path === 'string' &&
        typeof data.type === 'string'
    );
}

function isCartCustomOptionsValue(data: unknown): data is CartCustomOptionsValue {
    return (
        isObjectWithKey(data) &&
        (typeof data.value === 'string' || typeof data.value === 'number') &&
        isObjectWithKey(data.threeDParameter) &&
        typeof data.threeDParameter.name === 'string' &&
        typeof data.threeDParameter.genericCode === 'string'
    );
}

function isConfigurationAction(data: unknown): data is PromotionAction['configuration'] {
    return isObjectWithKey(data) && (typeof data.amount === 'number' || typeof data.percentage === 'number');
}

function isPromotionAction(data: unknown): data is PromotionAction {
    return isObjectWithKey(data) && typeof data.type === 'string' && isConfigurationAction(data.configuration);
}

function isGetawayConfig(data: unknown): data is GatewayConfig {
    return isObjectWithKey(data) && isObjectWithKey(data.publicConfig);
}

function isSelectedInstallationPartnerData(data: unknown): data is SelectedInstallationPartnerData {
    return isObjectWithKey(data) && isInstallationPartnerData(data.installationPartner) && isNumber(data.priority);
}

function isInstallationPartnerAssistanceType(data: unknown): data is InstallationPartnerAssistanceType {
    return isString(data) && (data === 'SELF' || data === 'RECEIVE_LIST' || data === 'PARTNER');
}

async function mapResponseDataToCartData(responseData: unknown): Promise<CartData> {
    const mediaDomain = await getPublicEnv('MEDIA_DOMAIN_URL');

    if (!isObjectWithKey(responseData)) {
        throw new Error('Invalid response');
    }

    return {
        items: getGenericArray(responseData.items, isCartItem).map(
            (item): CartItem => ({
                ...item,

                images: item.images.map((image) => ({
                    ...image,
                    path: `${mediaDomain}${image.path}`,
                })),
            }),
        ),
        currencyCode: getString(responseData.currencyCode, ''),
        payments: getGenericArray(responseData.payments, isPayment),
        total: getNumber(responseData.total),
        shippingTotal: getNumber(responseData.shippingTotal),
        orderPromotionTotal: getNumber(responseData.orderPromotionTotal),
        taxTotal: getNumber(responseData.taxTotal),
        promotionCoupon: getGenericValue(responseData.promotionCoupon, isOrderPromotionData, null),
        userAddressData: {
            customer: getGenericValue(responseData.customer, isCustomer, null),
            billingAddress: getGenericValue(responseData.billingAddress, isAddress, null),
            shippingAddress: getGenericValue(responseData.shippingAddress, isAddressWithElevator, null),
            payments: getGenericArray(responseData.payments, isPayment),
            shipments: getGenericArray(responseData.shipments, isShipment),
        },
        internalDeliveryDate: {
            internalDeliveryDate: isObjectWithKey(responseData.internalDeliveryDate)
                ? getString(responseData.internalDeliveryDate.internalDeliveryDate, '')
                : null,
        },
        checkoutState: getString(responseData.checkoutState),
        finalizationStatus: getString(responseData.finalizationStatus),
        number: getString(responseData.number),
        deliveryDate: getString(responseData.deliveryDate),
        installationAssistance: getGenericValue(
            responseData.installationAssistance,
            isInstallationPartnerAssistanceType,
            'RECEIVE_LIST',
        ),
        installationPartnerSelections: getGenericArray(
            responseData.installationPartnerSelections,
            isSelectedInstallationPartnerData,
        ),
    };
}

export class CartService {
    private fetch: FetchService;
    private localeCode = DEFAULT_LOCALE_CODE;
    private currencyCode: string;

    constructor(fetch: FetchService, currencyCode: string | null) {
        this.fetch = fetch;
        this.currencyCode = currencyCode || 'EUR';
    }

    async getCartIri(options?: OptionsType) {
        const cartToken = await this.getCurrentCartToken(options);

        return `/api/v2/shop/orders/${cartToken}`;
    }

    async getCurrentCartToken(options?: OptionsType) {
        const cartToken = getCookie('cartToken', options);

        if (typeof cartToken === 'string') {
            return cartToken;
        }

        return await this.refreshCartToken(options);
    }

    cartTokenExist(options?: OptionsType) {
        const cartToken = getCookie('cartToken', options);

        return typeof cartToken === 'string';
    }

    async refreshCartToken(options?: OptionsType) {
        const { tokenValue } = await this.createCart();

        setCookie('cartToken', tokenValue, {
            ...options,
            path: '/',
        });

        return tokenValue;
    }

    static clearCartTokenInCookies(options?: OptionsType) {
        deleteCookie('cartToken', options);
    }

    private async createCart(): Promise<CreateCartResponse> {
        const responseData = await this.fetch.post('/api/v2/shop/orders', {
            localeCode: this.localeCode,
            currencyCode: this.currencyCode,
        });

        if (!isObjectWithKey(responseData)) {
            throw new Error('Invalid response');
        }

        return {
            tokenValue: getString(responseData.tokenValue),
        };
    }

    async changeCurrencyCode(currencyCode: string): Promise<void> {
        this.currencyCode = currencyCode;
        const cartToken = await this.getCurrentCartToken();

        if (cartToken) {
            await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/currency`, {
                currencyCode,
            });
        }
    }

    async changeLocaleCode(localeCode: string): Promise<void> {
        this.localeCode = localeCode;
        const cartToken = await this.getCurrentCartToken();

        if (cartToken) {
            await this.fetch.put(`/api/v2/shop/orders/${cartToken}`, {
                localeCode,
            });
        }
    }

    async addProjectToCart(projectId: string): Promise<void> {
        const cartToken = await this.getCurrentCartToken();

        await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/project`, {
            project: `/api/v2/shop/projects/${projectId}`,
        });
    }

    async getCartData(options?: OptionsType): Promise<CartData> {
        const orderToken: string = await this.getCurrentCartToken(options);

        return this.getCartDataByOrderToken(orderToken);
    }

    async getCartDataByOrderToken(orderToken: string): Promise<CartData> {
        const responseData = await this.fetch.get(`/api/v2/shop/orders/${orderToken}`);

        return mapResponseDataToCartData(responseData);
    }

    changeCartItemQuantity = async (itemId: number, quantity: number): Promise<CartData> => {
        const cartToken = await this.getCurrentCartToken();

        if (!cartToken) {
            throw new Error('Cart token is required');
        }

        const responseData = await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/items/${itemId}`, {
            quantity,
        });

        return mapResponseDataToCartData(responseData);
    };

    deleteCartItem = async (itemId: number): Promise<void> => {
        const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');
        const cartToken = await this.getCurrentCartToken();

        if (cartToken) {
            await this.fetch.request(`${apiUrl}/shop/orders/${cartToken}/items/${itemId}`, {
                method: 'DELETE',
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
        }
    };

    applyCoupon = async (couponCode: string, options?: OptionsType): Promise<void> => {
        const cartToken = await this.getCurrentCartToken(options);

        await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/apply-coupon`, {
            couponCode,
        });
    };

    getPaymentMethods = async (method = 'stripe'): Promise<PaymentMethodsResponse | null> => {
        const responseData = await this.fetch.get(`/api/v2/shop/payment-methods/${method}`);

        if (isObjectWithKey(responseData)) {
            return {
                id: getNumber(responseData.id),
                code: getString(responseData.code),
                name: getString(responseData.name),
                gatewayConfig: getGenericValue(responseData.gatewayConfig, isGetawayConfig, null),
            };
        }

        return null;
    };

    selectPaymentMethod = async (paymentId: number, method = 'stripe'): Promise<SelectPaymentMethodResponse | null> => {
        const cartToken = await this.getCurrentCartToken();

        const responseData = await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/payments/${paymentId}`, {
            paymentMethod: `/api/v2/shop/payment-methods/${method}`,
        });

        if (!isObjectWithKey(responseData)) {
            throw new Error('Invalid response');
        }

        return {
            payments: getGenericArray(responseData.payments, isSelectMethodPayment),
        };
    };

    completePayment = async (data: CompletePaymentRequestData): Promise<void> => {
        const cartToken = await this.getCurrentCartToken();

        await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/complete`, data);
    };

    async deleteDiscountCode(): Promise<void> {
        const cartToken = await this.getCurrentCartToken();

        await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/reset-coupon`, {});
    }

    async getInstallationAssistance(): Promise<void> {
        const cartToken = await this.getCurrentCartToken();

        await this.fetch.get(`/api/v2/shop/orders/${cartToken}/installation-assistance`);
    }

    async setInstallationAssistance(installationAssistance: InstallationPartnerAssistanceType): Promise<void> {
        const cartToken = await this.getCurrentCartToken();

        await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/installation-assistance`, { installationAssistance });
    }

    async editUserAddress(data: UserAddressRequestData): Promise<void> {
        const cartToken = await this.getCurrentCartToken();

        await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/address`, data);
    }

    async editInstallationPartners(listOfID: number[]): Promise<CartData> {
        const cartToken = await this.getCurrentCartToken();

        const responseData = await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/installation-partners`, {
            installationPartnersSelections: listOfID.map((id, index) => ({
                installationPartner: `/api/v2/shop/installation-partners/${id}`,
                priority: index + 1,
            })),
        });

        return mapResponseDataToCartData(responseData);
    }

    async setDeliveryDate(deliveryDate: string): Promise<void> {
        const cartToken = await this.getCurrentCartToken();

        await this.fetch.patch(`/api/v2/shop/orders/${cartToken}/delivery-date`, {
            deliveryDate,
        });
    }

    async saveCart(data: SaveCartRequestParam): Promise<SaveCartResponse> {
        const cartToken = await this.getCurrentCartToken();

        const responseData = await this.fetch.post('/api/v2/shop/unavailable-checkout-intentions', {
            ...data,
            cart: `/api/v2/shop/orders/${cartToken}`,
        });

        if (!isObjectWithKey(responseData)) {
            throw new Error('Invalid response');
        }

        return {
            id: getString(responseData.id),
        };
    }
}
