import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    display: grid;
    align-content: center;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
`;
