import { createReducer } from '@reduxjs/toolkit';
import { CartData } from '../../types/Cart';
import { BlockItem } from '../../types/Project';
import {
    addDiscountCodeToOrder,
    removeDiscountCode,
    fetchOrderData,
    removeCartItem,
    setBlockItems,
    clearDiscountCodeData,
    setAvailableCountries,
    setAllCountries,
    updateUserAddress,
    setCountryForSavingProject,
    setOrderData,
    showLoaderOnCheckoutAction,
    setLoadingAddingProjectToCartStatus,
    setUpdateOrderByAdmin,
    changeCartItemQuantity,
} from './actions';

const DEFAULT_ERROR_MSG = 'Uh oh! There was an error!';

interface ProjectState {
    isAddingProject: boolean;
    orderData: CartData | null;
    blockItems: BlockItem[];
    allRemovingCartIds: number[];
    discountCodeError: string | null;
    isLoadingDiscountCode: boolean;
    allCountries: string[];
    availableCountries: string[];
    showLoaderOnCheckout: boolean;
    isFetchingCartData: boolean;
    selectedCountryForSavingProject: string | null;
    updateOrderByAdminSettings: {
        token: string;
        orderItemId: string;
    } | null;
}

const initialState: ProjectState = {
    isAddingProject: false,
    orderData: null,
    blockItems: [],
    allRemovingCartIds: [],
    discountCodeError: null,
    isLoadingDiscountCode: false,
    allCountries: [],
    availableCountries: [],
    showLoaderOnCheckout: false,
    isFetchingCartData: false,
    selectedCountryForSavingProject: null,
    updateOrderByAdminSettings: null,
};

const project = createReducer(initialState, ({ addCase }) => {
    addCase(setUpdateOrderByAdmin, (state, { payload }) => {
        state.updateOrderByAdminSettings = payload;
    });

    addCase(setLoadingAddingProjectToCartStatus, (state, { payload }) => {
        state.isAddingProject = payload;
    });
    addCase(changeCartItemQuantity.pending, (state) => {
        state.isFetchingCartData = true;
    });
    addCase(changeCartItemQuantity.rejected, (state) => {
        state.isFetchingCartData = false;
    });
    addCase(changeCartItemQuantity.fulfilled, (state, action) => {
        state.orderData = action.payload;
        state.isFetchingCartData = false;
    });
    addCase(fetchOrderData.pending, (state) => {
        state.isFetchingCartData = true;
    });
    addCase(fetchOrderData.fulfilled, (state, action) => {
        state.orderData = action.payload;
        state.isFetchingCartData = false;
    });
    addCase(fetchOrderData.rejected, (state) => {
        state.isFetchingCartData = false;
    });
    addCase(setBlockItems, (state, action) => {
        state.blockItems = action.payload;
    });
    addCase(setAvailableCountries, (state, action) => {
        state.availableCountries = action.payload.map((item) => item.code);
    });
    addCase(setAllCountries, (state, action) => {
        state.allCountries = action.payload.map((item) => item.code);
    });
    addCase(removeCartItem.pending, (state, action) => {
        state.allRemovingCartIds.push(action.meta.arg);
    });
    addCase(removeCartItem.fulfilled, (state, action) => {
        state.allRemovingCartIds = state.allRemovingCartIds.filter((id) => id !== action.meta.arg);
    });
    addCase(removeCartItem.rejected, (state, action) => {
        state.allRemovingCartIds = state.allRemovingCartIds.filter((id) => id !== action.meta.arg);
    });
    addCase(clearDiscountCodeData, (state) => {
        state.discountCodeError = null;
    });
    addCase(removeDiscountCode.pending, (state) => {
        state.isLoadingDiscountCode = true;
    });
    addCase(removeDiscountCode.fulfilled, (state) => {
        state.discountCodeError = null;
        state.isLoadingDiscountCode = false;
    });
    addCase(removeDiscountCode.rejected, (state) => {
        state.isLoadingDiscountCode = false;
    });
    addCase(addDiscountCodeToOrder.pending, (state) => {
        state.discountCodeError = null;
        state.isLoadingDiscountCode = true;
    });
    addCase(addDiscountCodeToOrder.fulfilled, (state) => {
        state.isLoadingDiscountCode = false;
    });
    addCase(addDiscountCodeToOrder.rejected, (state) => {
        // TODO handle and show api messages when api will be ready
        state.discountCodeError = DEFAULT_ERROR_MSG;
        state.isLoadingDiscountCode = false;
    });
    addCase(updateUserAddress.pending, (state) => {
        state.showLoaderOnCheckout = true;
    });
    addCase(updateUserAddress.fulfilled, (state) => {
        state.showLoaderOnCheckout = false;
    });
    addCase(showLoaderOnCheckoutAction, (state, { payload }) => {
        state.showLoaderOnCheckout = payload;
    });
    addCase(updateUserAddress.rejected, (state) => {
        state.showLoaderOnCheckout = false;
    });
    addCase(setCountryForSavingProject, (state, action) => {
        state.selectedCountryForSavingProject = action.payload;
    });
    addCase(setOrderData, (state, action) => {
        state.orderData = action.payload;
    });
});

export default project;
