import { createReducer } from '@reduxjs/toolkit';
import { setAllProducts } from './actions';
import { Product } from '../../types/Product';
import { AllProducts, formatProducts } from '../../services/nodes/wardrobe/wardrobe';

interface ProductsState {
    allProducts: Product[];
    formattedProducts: AllProducts | null;
}

const initialState: ProductsState = {
    allProducts: [],
    formattedProducts: null,
};

export default createReducer(initialState, (builder) => {
    builder.addCase(setAllProducts, (state, action) => {
        state.allProducts = action.payload;
        state.formattedProducts = formatProducts(action.payload);
    });
});
