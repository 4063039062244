import React, { FC, useState, useCallback, useEffect } from 'react';
import { Button } from '@formify/frontend-components';
import { CardWrapperStyled, ShippingInfoStyled, SummaryWrapper, SummaryFooter, IconWrapper } from './Summary.styled';
import { useDispatch, useSelector } from 'react-redux';
import { LinkL } from '@formify/frontend-components';
import {
    getCartItems,
    getBlockHTML,
    getTotalCartPrice,
    getAllRemovingCartIds,
    getIsFetchingCartData,
} from '../../../../store/project/selectors';
import { CardVertical } from '../../../Card/components/CardVertical/CardVertical';
import { clearDiscountCodeData } from '../../../../store/project/actions';
import { DiscountCode } from '../DiscountCode/DiscountCode';
import { CarouselIndexes, SliderWithDetails } from '../SliderWithDetails/SliderWithDetails';
import { EmptyCart } from './components/EmptyCart/EmptyCart';
import { ScrollableContentInSidePanel } from '../../../Sidebar/components/ScrollableContentInSidePanel/ScrollableContentInSidePanel';
import { useRouter } from 'next/router';
import { getCartItemSubtitle } from '../../../Card/utils';
import { RouteName } from '../../../../constants/router';
import { getProjectImageByType } from '../../../../services/project/project';
import { ChevronLeftIcon } from '../../../icons/ChevronLeft/ChevronLeft';
import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { CartItem } from '../../../../types/Cart';
import { SummaryDetails } from './components/SummaryDetails/SummaryDetails';
import { getProjectIdFromProject } from './utils';
import { LoadingOverlay } from '../../../Checkout/LoadingOverlay/LoadingOverlay';
import { SubtitleXS } from '@formify/frontend-components';
import { useLanguage } from '../../../Translations/LanguageProvider';
import { Tr } from '../../../Translations/Tr';
import { isWardrobeCode } from '../../../../services/products/domain/ProductCodes';
import { useCurrency } from '../../../Currency/CurrencyProvider';

interface SummaryProps {
    onClose: () => void;
    isOpen: boolean;
}

export const Summary: FC<SummaryProps> = ({ onClose, isOpen }) => {
    const cartItems = useSelector(getCartItems);
    const isLoading = useSelector(getIsFetchingCartData);
    const totalCartPrice = useSelector(getTotalCartPrice);
    const { t, currentLanguage } = useLanguage();
    const shippingInfoHTML = useSelector(getBlockHTML('shipping_info', currentLanguage));
    const allRemovingCartIds = useSelector(getAllRemovingCartIds);
    const [selectedSlideIndex, setSelectedSlideIndex] = useState(CarouselIndexes.Main);
    const dispatch = useDispatch<any>();
    const areItemsAvailableAndNotLoading = cartItems && cartItems.length > 0 && !isLoading;
    const { isMobile } = useBreakpoints();
    const [selectedCartItem, setSelectedCartItem] = useState<CartItem | null>(null);
    const router = useRouter();
    const { formatPrice } = useCurrency();

    const showDetails = useCallback((item: CartItem) => {
        setSelectedCartItem(item);
        setSelectedSlideIndex(CarouselIndexes.Details);
    }, []);

    const isRemovingCart = useCallback((id: number) => allRemovingCartIds.includes(id), [allRemovingCartIds]);

    const goToProject = useCallback((item: CartItem) => {
        const projectId = getProjectIdFromProject(item);

        window.location.href = `${window.location.origin}/open-project?projectId=${projectId}`;
    }, []);

    useEffect(() => {
        if (!areItemsAvailableAndNotLoading) {
            dispatch(clearDiscountCodeData());
        }
    }, [areItemsAvailableAndNotLoading, dispatch]);

    return (
        <SliderWithDetails
            overlay
            selectedSlideIndex={selectedSlideIndex}
            isOpen={isOpen}
            details={
                selectedCartItem ? (
                    <SummaryDetails
                        item={selectedCartItem}
                        onClose={() => {
                            setSelectedSlideIndex(CarouselIndexes.Main);
                            setSelectedCartItem(null);
                        }}
                    />
                ) : null
            }
            onClose={isOpen ? onClose : undefined}
            isMobileFullWidth={isMobile}
        >
            {isMobile && (
                <IconWrapper>
                    <Button variant="outlined-white" icon={<ChevronLeftIcon />} onClick={onClose}></Button>
                </IconWrapper>
            )}
            {isLoading && <LoadingOverlay />}
            <ScrollableContentInSidePanel
                dataTestId="design_summary"
                title={t('Your Design Summary', 'Your Design Summary')}
                sticky={
                    <SummaryFooter disable={!areItemsAvailableAndNotLoading}>
                        {typeof totalCartPrice === 'number' && (
                            <div>
                                <LinkL>{formatPrice(totalCartPrice)}</LinkL>
                                <SubtitleXS>{t('including VAT', 'including VAT')}</SubtitleXS>
                            </div>
                        )}
                        <Button
                            variant="primary"
                            onClick={() =>
                                router.push({
                                    pathname: RouteName.Checkout,
                                    query: {
                                        step: 'address',
                                    },
                                })
                            }
                            className="ml-auto"
                            dataTestId="go-to-checkout-button"
                        >
                            <Tr labelKey="checkout_button" defaultValue="Checkout" />
                        </Button>
                    </SummaryFooter>
                }
            >
                <SummaryWrapper>
                    {cartItems && cartItems.length > 0 ? (
                        <>
                            <div className="flex flex-col">
                                {cartItems.map((item, index) => (
                                    <CardWrapperStyled key={index}>
                                        <CardVertical
                                            dataTestId={`project_id_${item.id}`}
                                            itemId={item.id}
                                            quantity={item.quantity}
                                            loading={isRemovingCart(item.id)}
                                            disabled={isRemovingCart(item.id)}
                                            onClickImage={() => {
                                                goToProject(item);
                                            }}
                                            title={t(`product.name.${item.productCode}`, item.productName)}
                                            subtitle={getCartItemSubtitle(item, t)}
                                            price={item.total}
                                            handleDetailsClick={
                                                isWardrobeCode(item.productCode) ? () => showDetails(item) : undefined
                                            }
                                            imgPath={getProjectImageByType(item.images, 'with-door')}
                                        />
                                    </CardWrapperStyled>
                                ))}
                            </div>
                            <DiscountCode withBorder />
                            {shippingInfoHTML && (
                                <ShippingInfoStyled
                                    className="bullet-list"
                                    dangerouslySetInnerHTML={{ __html: shippingInfoHTML }}
                                />
                            )}
                        </>
                    ) : (
                        <EmptyCart onClose={onClose} />
                    )}
                </SummaryWrapper>
            </ScrollableContentInSidePanel>
        </SliderWithDetails>
    );
};
