import styled from 'styled-components';
import Breakpoint from '../../constants/Breakpoint.enum';
import { Color } from '../../constants/Color.enum';

export const Container = styled.div`
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    z-index: 100;
    align-items: center;
    width: 355px;
    border-radius: 4px;
    border: 1px solid ${Color.Black};
    position: fixed;
    bottom: 10px;
    padding: 40px 30px 30px 30px;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: ${Breakpoint.Tablet}) {
        right: 10px;
        transform: none;
        left: auto;
    }

    @media (min-width: ${Breakpoint.Desktop}) {
        width: 420px;
        padding: 30px;
    }
`;
