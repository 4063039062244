import { useSelector } from 'react-redux';
import { getIsHeaderVisible } from '../store/layout/selectors';
import { HEADER_MOBILE_HEIGHT, HEADER_DESKTOP_HEIGHT } from '../components/Header/Header.styled';
import { useBreakpoints } from './useBreakpoints';

export const useHeaderOffset = () => {
    const isHeaderVisible = useSelector(getIsHeaderVisible);
    const { isMobile } = useBreakpoints();

    if (!isHeaderVisible) {
        return '0px';
    }
    if (isMobile) {
        return HEADER_MOBILE_HEIGHT;
    }

    return HEADER_DESKTOP_HEIGHT;
};
