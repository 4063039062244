import { useCallback } from 'react';
import { useRouter } from 'next/router';

interface QueryParams {
    [key: string]: string | string[] | undefined;
}

export const useRouterParams = <QP extends QueryParams>() => {
    const router = useRouter();

    return {
        params: router.query as QP,
        setParam: useCallback(
            <T extends keyof QP>(key: T, value: QP[T], shallow = true) => {
                const updatedQueryParams = {
                    ...router.query,
                    [key]: value,
                };

                router.push(
                    {
                        pathname: router.pathname,
                        query: updatedQueryParams,
                    },
                    undefined,
                    {
                        shallow,
                    },
                );
            },
            [router],
        ),
        setParams: useCallback(
            (partialQuery: Partial<QP>, shallow = true) => {
                const updatedQueryParams = {
                    ...router.query,
                    ...partialQuery,
                };

                router.push(
                    {
                        pathname: router.pathname,
                        query: updatedQueryParams,
                    },
                    undefined,
                    {
                        shallow,
                    },
                );
            },
            [router],
        ),
    } as const;
};
