import styled from 'styled-components';
import { getTextExtraSmallStyles } from '@formify/frontend-components/dist/components/Typography/Text';

export const CardWrapperStyled = styled.div`
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 16px;
    }
`;

export const ShippingInfoStyled = styled.div`
    ul {
        margin: 16px;
        text-align: left;

        ${getTextExtraSmallStyles}

        li:not(:last-child) {
            margin-bottom: 10px;
        }
    }
`;

export const SummaryWrapper = styled.div`
    width: 100%;
    max-width: 336px;
    margin: auto;
`;

export const SummaryFooter = styled.div<{ disable: boolean }>`
    width: 100%;
    max-width: 336px;
    margin: auto;
    display: flex;
    align-items: center;
    ${({ disable }) =>
        disable
            ? `
    opacity: 0.8;
    pointer-events:none;
    `
            : ''}
`;

export const IconWrapper = styled.div`
    margin: 16px;
    cursor: pointer;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
`;
