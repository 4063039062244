import { createReducer } from '@reduxjs/toolkit';
import { popToastr, showToastr } from './actions';

export interface Toastr {
    description: string;
}

type ToastrState = {
    data: Toastr[];
};

const initialState: ToastrState = {
    data: [],
};

export const toastrReducer = createReducer(initialState, (builder) => {
    builder.addCase(showToastr, (state, action) => {
        state.data = [action.payload, ...state.data];
    });

    builder.addCase(popToastr, (state) => {
        const copy = [...state.data];

        copy.pop();

        state.data = copy;
    });
});
