import { useState, RefObject } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export const useSize = (ref: RefObject<HTMLElement>) => {
    const [size, setSize] = useState<DOMRect>();

    useResizeObserver(ref, (entry) => setSize(entry.contentRect));

    return size;
};
