import styled from 'styled-components';
import { Color } from '../../../../constants/Color.enum';
import Breakpoint from '../../../../constants/Breakpoint.enum';

export const CardHorizontalWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    height: 166px;
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    width: 100%;
    background: #fff;
`;

export const LeftWrapper = styled.div`
    background-color: ${Color.Background};
    min-width: 140px;
`;

export const DetailsButtonWrapper = styled.div`
    button {
        width: auto;
        padding: 0;
        left: 0;
    }
`;

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${Color.Background};
    border-bottom: 1px solid ${Color.Background};
    justify-content: space-between;
    flex-grow: 1;
    padding: 20px 16px;

    @media (max-width: ${Breakpoint.Mobile}) {
        padding: 20px 16px;
    }
`;

export const LoadingWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
