import { GetProjectResponse } from '../../types/Project';
import { getPublicEnv } from '../env/env';
import { FetchService } from '../fetch/fetch';
import { isGetProjectResponse } from '../project/project';
import { getGenericArray, isBoolean, isNumber, isObjectWithKey, isString } from '../validateData';
import { UserAddressStorage } from './domain';

function isUserAddressStorage(data: any): data is UserAddressStorage {
    return (
        isObjectWithKey(data) &&
        isNumber(data.id) &&
        isString(data.city) &&
        isString(data.countryCode) &&
        isString(data.firstName) &&
        isNumber(data.floor) &&
        isBoolean(data.hasElevator) &&
        isBoolean(data.isDefault) &&
        isString(data.lastName) &&
        isString(data.notes) &&
        isString(data.phoneNumber) &&
        isString(data.postcode) &&
        isString(data.street)
    );
}
export class UserService {
    private fetch: FetchService;

    constructor(fetch: FetchService) {
        this.fetch = fetch;
    }

    async getProject(): Promise<GetProjectResponse[]> {
        const response = await this.fetch.get('/api/v2/shop/projects');
        const mediaUrl = await getPublicEnv('MEDIA_DOMAIN_URL');

        if (Array.isArray(response)) {
            const projects = getGenericArray(response, isGetProjectResponse).map((responseData) => ({
                ...responseData,
                mainItem: {
                    ...responseData.mainItem,
                    images: responseData.mainItem.images.map((img) => ({
                        ...img,
                        path: `${mediaUrl}${img.path}`,
                    })),
                },
            }));

            return projects;
        }

        return [];
    }

    async unlinkProject(id: string): Promise<void> {
        await this.fetch.delete(`/api/v2/shop/projects/${id}`);
    }

    async getAllAddresses(): Promise<UserAddressStorage[]> {
        const response = await this.fetch.get('/api/v2/shop/addresses');

        if (Array.isArray(response)) {
            const addresses = getGenericArray(response, isUserAddressStorage);
            const collectionHasDefaultAddress = addresses.some((address) => address.isDefault);

            if (collectionHasDefaultAddress) {
                return addresses;
            }

            const [firstAddress, ...rest] = addresses;

            if (firstAddress) {
                return [
                    {
                        ...firstAddress,
                        isDefault: true,
                    },
                    ...rest,
                ];
            }

            return [];
        }

        return [];
    }

    async getDefaultAddress(): Promise<UserAddressStorage | null> {
        try {
            const addresses = await this.getAllAddresses();
            const defaultAddress = addresses.find((address) => address.isDefault);

            return defaultAddress || null;
        } catch (error) {
            return null;
        }
    }

    async updateAddress(
        values: Partial<UserAddressStorage> & Pick<UserAddressStorage, 'id'>,
    ): Promise<UserAddressStorage> {
        const response = await this.fetch.put(`/api/v2/shop/addresses/${values.id}`, values);

        if (isUserAddressStorage(response)) {
            return response;
        }

        throw new Error('Update result is invalid');
    }

    async deleteAddress(values: Pick<UserAddressStorage, 'id'>): Promise<void> {
        await this.fetch.delete(`/api/v2/shop/addresses/${values.id}`);
    }

    async addAddress(values: Omit<UserAddressStorage, 'id'>): Promise<void> {
        await this.fetch.post('/api/v2/shop/addresses', values);
    }
}
