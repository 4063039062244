import styled, { css } from 'styled-components';
import { Color } from '../../../constants/Color.enum';

interface TextInputStyledProps {
    isEmpty?: boolean;
    error?: boolean;
    success?: boolean;
    isIcon?: boolean;
    disabled?: boolean;
    blocked?: boolean;
    textarea?: boolean;
    responsiveWidth?: boolean;
}
interface TextareaLabelStyledProps {
    show?: boolean;
}

export const TAXTAREA_LABEL_MARGIN = 20;

export const getColorByStatus = (props: TextInputStyledProps, defaultColor?: Color) => {
    if (props.success) {
        return Color.Input_Success;
    }
    if (props.error) {
        return Color.Error;
    }

    return defaultColor || Color.Secondary;
};

const textInputBaseStyles = css<TextInputStyledProps>`
    width: 100%;
    border: 1px solid ${(props) => getColorByStatus(props, Color.Variant_1)};
    border-radius: 10px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.75px;
    font-weight: 300;
    outline: none !important;
    background-color: ${(props) => {
        if (props.success) {
            return 'rgba(13, 143, 108, 0.1)';
        }
        if (props.error) {
            return 'rgba(203, 68, 75, 0.1)';
        }
    }};
    padding-right: 50px;

    &:active {
        border: 1px solid ${Color.Primary};
    }
    &:disabled {
        background-color: ${Color.Background};
        border: 1px solid ${Color.Variant_1};
        pointer-events: none;
    }
`;

export const StandardLabelStyled = styled.label<TextInputStyledProps>`
    position: absolute;
    font-size: 14px;
    transform: ${(props) => (!props.isEmpty ? 'translateY(13px) scale(0.71)' : 'translateY(21px) scale(1)')};
    transform-origin: top left;
    transition: all 0.3s ease-in;
    left: ${(props) => (props.isIcon ? '64px' : '25px')};
    color: ${(props) => getColorByStatus(props, !props.isEmpty ? undefined : Color.Variant_1)};
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    pointer-events: none;
`;

export const TextInputStandardStyled = styled.input<TextInputStyledProps>`
    ${textInputBaseStyles}

    height: 64px;
    padding-left: ${(props) => (props.isIcon ? '64px' : '24px')};
    padding-top: 15px;
    pointer-events: ${({ blocked }) => (blocked ? 'none' : 'inherit')};

    &:active {
        border: 1px solid ${Color.Primary};
    }
    &:focus {
        padding-top: 15px;
        border: 1px solid;
        border-color: ${(props) => getColorByStatus(props, Color.Primary)};

        + label {
            transform: translateY(13px) scale(0.71);
            color: ${getColorByStatus};
        }
    }
    &:disabled {
        background-color: ${Color.Background};
        border: 1px solid ${Color.Variant_1};
        pointer-events: none;
    }
`;

export const TextAreaStyled = styled.textarea<TextInputStyledProps>`
    ${textInputBaseStyles}
    font-size: 16px;
    padding: 40px 24px;
    height: 128px;

    &:focus {
        border: 1px solid ${Color.Primary};

        + label {
            transform: translateX(25px) translateY(13px) scale(0.71);
        }
    }

    &:disabled {
        opacity: 0.3;
    }

    ::-webkit-resizer,
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const TextAreaGrabberStyled = styled.div`
    position: relative;
    display: inline-block;

    &::after {
        content: '';
        border-top: 1.5px solid ${Color.Secondary};
        width: 16px;
        transform: rotate(-45deg);
        z-index: 1;
        background: transparent;
        position: absolute;
        right: 15px;
        bottom: 20px;
        pointer-events: none;
        border-radius: 25%;
    }
`;

export const GrabberPullTabStyled = styled.div`
    border-top: 1.5px solid ${Color.Secondary};
    width: 8px;
    transform: rotate(-45deg);
    position: absolute;
    bottom: 17px;
    right: 15px;
    pointer-events: none;
    z-index: 2;
    border-radius: 25%;
`;

export const TextInputWrapperStyled = styled.div<TextInputStyledProps>`
    width: ${(props) => (props.responsiveWidth ? '100%' : '325px')};
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const IconRightWrapperStyled = styled.div<TextInputStyledProps>`
    position: absolute;
    align-self: ${(props) => (props.textarea ? 'start' : 'center')};
    right: ${(props) => (props.textarea ? '3px' : '13px')};
    padding: 10px;
    margin-top: ${(props) => (props.textarea ? '3px' : '0')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
    cursor: pointer;
    color: ${Color.Black};
    height: 100%;
    display: flex;
    align-items: ${(props) => (props.textarea ? 'flex-start' : 'center')};
`;

export const IconLeftWrapperStyled = styled.div`
    position: absolute;
    left: 24px;
    top: 15px;
    color: ${Color.Primary};

    svg {
        width: 30px;
        height: 30px;
    }
`;

export const TextAreaLabelStyled = styled.label<TextInputStyledProps & TextareaLabelStyledProps>`
    position: absolute;
    transition: all 0.3s ease-in;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: -${TAXTAREA_LABEL_MARGIN}px;
    color: ${(props) => getColorByStatus(props, Color.Variant_1)};
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    transform-origin: left;
    pointer-events: none;
    visibility: ${(props) => (props.show ? 'initial' : 'hidden')};
    transform: ${(props) =>
        !props.isEmpty
            ? 'translateX(25px) translateY(13px) scale(0.71)'
            : 'translateY(28px) scale(1) translateX(25px)'};
`;
