import { createReducer } from '@reduxjs/toolkit';
import { StaticPageSimple } from '../../services/staticPages/staticPages';
import { setCookieVisibility, setHeaderVisibility, setPages } from './actions';

interface LayoutState {
    isHeaderVisible: boolean;
    isCookieVisible: boolean;
    staticPages: StaticPageSimple[];
}

const initialState: LayoutState = {
    isHeaderVisible: false,
    isCookieVisible: false,
    staticPages: [],
};

export default createReducer(initialState, (builder) => {
    builder.addCase(setHeaderVisibility, (state, action) => {
        state.isHeaderVisible = action.payload;
    });
    builder.addCase(setCookieVisibility, (state, action) => {
        state.isCookieVisible = action.payload;
    });
    builder.addCase(setPages, (state, action) => {
        state.staticPages = action.payload;
    });
});
