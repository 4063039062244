import { useLayoutEffect, useState } from 'react';
import { useChannel } from '../Channel/ChannelProvider';
import { useLanguage } from '../Translations/LanguageProvider';
import Head from 'next/head';

export const OgMetaTags = () => {
    const [origin, setOrigin] = useState<string>(process.env.NEXT_PUBLIC_API_DOMAIN || '');
    const { name } = useChannel();
    const { currentLanguage } = useLanguage();

    useLayoutEffect(() => {
        if (typeof window !== 'undefined') {
            setOrigin(window.location.origin);
        }
    }, []);

    return (
        <>
            {origin !== '' ? (
                <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                    <meta property="og:title" content="test title" />
                    <meta property="og:description" content="test description 2" />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={name} />
                    <meta property="og:locale" content={currentLanguage} />

                    <meta property="og:image" content={`${origin}/ogImageSmall.jpg`} />
                    <meta property="og:image:width" content="600" />
                    <meta property="og:image:height" content="315" />

                    <meta property="og:image" content="https://www.formifydev.com/ogImage.jpg" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />

                    <meta property="og:image" content={`${origin}/ogImageSquare.jpg`} />
                    <meta property="og:image:width" content="256" />
                    <meta property="og:image:height" content="256" />
                </Head>
            ) : (
                <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                </Head>
            )}
        </>
    );
};
