import { createReducer } from '@reduxjs/toolkit';
import { MediaType } from '../../services/media/domain/MediaType';
import { setAllMedia } from './actions';

export type Media = {
    image: string;
    name: {
        [language: string]: string;
    };
    description: {
        [language: string]: string;
    };
};

export type SimplifyMedia = {
    image: string;
    name: string;
    description: string;
};

type MediaState = {
    [type in MediaType]?: Media;
};

const initialState: MediaState = {};

export const mediaReducer = createReducer(initialState, (builder) => {
    builder.addCase(setAllMedia, (state, action) => {
        state = action.payload.reduce<MediaState>((acc, item) => {
            const { name, description } = Object.keys(item.translations).reduce<{
                name: {
                    [language: string]: string;
                };
                description: {
                    [language: string]: string;
                };
            }>(
                (acc, languageCode) => {
                    const value = item.translations[languageCode];

                    if (value) {
                        acc.name[languageCode] = value.name;
                        acc.description[languageCode] = value.content;
                    }

                    return acc;
                },
                { name: {}, description: {} },
            );

            acc[item.code] = {
                image: item.path,
                name,
                description,
            };

            return acc;
        }, state);
    });
});
