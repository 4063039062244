import React, { FC, useCallback } from 'react';
import {
    getDiscountCodeError,
    getIsLoadingDiscountCode,
    getOrderPromotionCode,
    getOrderPromotionDiscount,
} from '../../../../store/project/selectors';
import { addDiscountCodeToOrder, removeDiscountCode } from '../../../../store/project/actions';
import { Accordion } from '../../../Accordion/Accordion';
import { TextInputWithButton } from '../../../TextInputWithButton/TextInputWithButton';
import { Message } from '../../../atoms/Message/Message';
import { SuccessMessage } from '../../../atoms/Message/SuccessMessage/SuccessMessage';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { discountValidator } from './validators/discountValidator';
import { useLanguage } from '../../../Translations/LanguageProvider';
import { useCurrency } from '../../../Currency/CurrencyProvider';

type DiscountCodeProps = {
    withBorder?: boolean;
};

interface DiscountCodeFormFields {
    discountCode: string;
}

export const DiscountCode: FC<DiscountCodeProps> = ({ withBorder }) => {
    const discountCode = useSelector(getOrderPromotionCode) ?? undefined;
    const { formatPrice } = useCurrency();
    const discountAmount = useSelector(getOrderPromotionDiscount(formatPrice));
    const isLoadingDiscountCode = useSelector(getIsLoadingDiscountCode);
    const discountCodeApiError = useSelector(getDiscountCodeError);
    const dispatch = useDispatch<any>();
    const { t } = useLanguage();

    const formMethods = useForm<DiscountCodeFormFields>({
        defaultValues: {
            discountCode,
        },
        resolver: yupResolver(discountValidator),
    });

    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = formMethods;

    const showDiscountError = Boolean(errors.discountCode) || Boolean(discountCodeApiError);
    const showDiscountSuccess = discountAmount !== null;
    const discountCodeHasBeenVerified = Boolean(discountCodeApiError) || showDiscountSuccess;

    const discountCodeErrorMessage =
        errors.discountCode?.type === 'required' ? 'This field is required' : discountCodeApiError || '';

    const onClear = useCallback(async () => {
        await dispatch(removeDiscountCode());
        reset();
    }, [dispatch, reset]);

    const onDiscountCodeSubmit = useCallback(
        ({ discountCode }: DiscountCodeFormFields) => {
            dispatch(addDiscountCodeToOrder(discountCode));
        },
        [dispatch],
    );

    return (
        <Accordion title={t('Use a discount code').toUpperCase()} withBorder={withBorder}>
            <div className="mt-16 pb-20">
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(onDiscountCodeSubmit)}>
                        <TextInputWithButton
                            blocked={discountCodeHasBeenVerified}
                            isLoading={isLoadingDiscountCode}
                            label="Card number"
                            buttonTitle={discountCodeHasBeenVerified ? undefined : t('add')}
                            responsiveWidth
                            name="discountCode"
                            onClear={discountCodeHasBeenVerified ? onClear : undefined}
                            error={showDiscountError}
                            success={showDiscountSuccess}
                        />
                        {showDiscountError && <Message error message={discountCodeErrorMessage} />}
                        {showDiscountSuccess && discountAmount && (
                            <SuccessMessage successMessage={t('Discount redeemed: ')} successValue={discountAmount} />
                        )}
                    </form>
                </FormProvider>
            </div>
        </Accordion>
    );
};
