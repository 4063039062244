import { getCookie, removeCookies } from 'cookies-next';
import { NextPageContext } from 'next';
import { IAuth } from './authServiceFactory';

export class SSRAuth implements IAuth {
    private context: Pick<NextPageContext, 'req' | 'res'>;

    constructor(context: Pick<NextPageContext, 'req' | 'res'>) {
        this.context = context;
    }

    async getSyliusToken(): Promise<string | null> {
        const apiToken = getCookie('apiToken', this.context);

        if (typeof apiToken === 'string') {
            return apiToken;
        }

        return null;
    }

    async resetPasswordByEmail(): Promise<unknown> {
        throw new Error('SSR Auth not support reset password');
    }

    async signInWithGoogle() {
        throw new Error('SSR Auth not support google auth');
    }
    async signInWithIOS() {
        throw new Error('SSR Auth not support iOS auth');
    }
    async signInWithEmail() {
        throw new Error('SSR Auth not support email auth');
    }
    async createUserWithEmailAndPassword() {
        throw new Error('SSR Auth not support email auth');
    }
    async confirmPasswordReset() {
        throw new Error('SSR Auth not support email auth');
    }
    async verifyEmailAddress() {
        throw new Error('SSR Auth not support email auth');
    }

    async onAuthStateChanged() {}
    async signOut() {
        removeCookies('apiToken', this.context);
    }
}
