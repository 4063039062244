import { createReducer } from '@reduxjs/toolkit';
import { setIframeSettings, setPhonePrefix } from './actions';
import { PhonePrefix } from '../../services/phonePrefix/phonePrefix';
import { EventsToPayload } from 'formify-sdk/dist/events/domain';

interface ConfigurationsState {
    phonePrefix: PhonePrefix[];
    iframeSettings: null | EventsToPayload['setSetting'];
}

const initialState: ConfigurationsState = {
    phonePrefix: [],
    iframeSettings: null,
};

export const configReducer = createReducer(initialState, (builder) => {
    builder.addCase(setPhonePrefix, (state, action) => {
        state.phonePrefix = action.payload;
    });
    builder.addCase(setIframeSettings, (state, action) => {
        state.iframeSettings = action.payload;
    });
});
