import { Selector } from 'react-redux';
import { RootServices } from '../../services';
import type { RootState } from '../index';

export const getServices: Selector<RootState, RootServices> = (state) => {
    if (state.dependency.services === null) {
        throw new Error('services not exist in store state');
    }

    return state.dependency.services;
};
