import styled from 'styled-components';
import { getColorByStatus } from '../TextInput/TextInput.styled';

interface MessageProps {
    error?: boolean;
    success?: boolean;
}

export const MessageStyled = styled.div<MessageProps>`
    font-weight: 300;
    margin-left: 1px;
    color: ${getColorByStatus};
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0.75px;
`;
